import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import { add_my_div } from './shopify';
import { render } from 'react-dom';
import './lib/sbvShopify';
import { BASE_URL, IS_LOCAL } from "../src/lib/common";
import { trackGetJSONCalls, getJSONCallCount } from './lib/JQueryWrapper';
import { showHelpMessage } from './lib/Help';

function start_mix_and_match() {
  console.log('Starting Mix and Match');
  // let shopValue = 'sbv-show.myshopify.com';
  // let shopValue = 'style-by-vida-dev.myshopify.com';
  let shopValue = 'puneet-ag-store.myshopify.com';

  

  
  if (!IS_LOCAL) {
    if (document.getElementById("sbv-mnm-div") && document.getElementById("sbv-mnm-div").shadowRoot !== null) {
      return;
    }
    const myQuery = "script[src^='" + BASE_URL + "/sbv-mix-and-match-3d']";
    console.log("myQuery = ", myQuery, ", BASE_URL=", BASE_URL)
    const scriptElement = document.querySelector(myQuery);
    let url = "";
    if (scriptElement) {
      url = new URL(scriptElement.src);
    } else {
      console.error("Show a help message to Insert Plug-in - TBD");
    }
    const searchParams = new URLSearchParams(url.search);
    shopValue = searchParams.get('shop');
  }
  console.log('Shop value:', shopValue);
  // trackGetJSONCalls(shopValue);
  let mnm_body = null;
  if (document.getElementById("sbv-mnm-shadow-body")) {
    console.log("The div with id 'sbv-mnm-shadow-body' exists.");
  } else {
    mnm_body = document.createElement('div');
    mnm_body.setAttribute('id', 'sbv-mnm-shadow-body');
    mnm_body.style.position = 'relative';
    const pElement = document.createElement('p');
    pElement.innerHTML = 'Hi there';
    mnm_body.appendChild(pElement);
  }
  const link = document.createElement("link");
  link.rel = "stylesheet";
  link.type = "text/css";
  link.href = BASE_URL + "/static/css/sbv-mnm-v3.css";
  let shadowDOM = null
  if (document.getElementById("sbv-mnm-div")) {
    console.log("Now Rendering Mix and Match 3D");
    // TODO: remove help from page
    const mnm_div = document.getElementById("sbv-mnm-div");
    if (!mnm_div.shadowRoot) {
      shadowDOM = mnm_div.attachShadow({ mode: 'open' });
      let head = document.createElement('head');
      let body = document.createElement('body');
      head.appendChild(link);
      body.appendChild(mnm_body);
      shadowDOM.appendChild(head);
      shadowDOM.appendChild(body);
    }
    render(<App shopValue={shopValue} />, mnm_body);
  }
  else if (IS_LOCAL) {
    console.log("Running Locally for Debugging by Developer");
    render(<App shopValue={shopValue} />, mnm_body);
    // shadowDOM = document.body.attachShadow({ mode: 'open' });
    let head = document.createElement('head');
    let body = document.createElement('body');
    // head.appendChild(link);
    body.appendChild(mnm_body);
    document.body.appendChild(head);
    document.body.appendChild(body);
    // console.log(shadowDOM);
  }
  else {
    console.log("It will show the Help Message now...!");
    showHelpMessage(document, shopValue);
  }
}

start_mix_and_match();


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
