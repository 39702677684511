import React, { useEffect, useRef, useState } from 'react';
import MainDressViewer4 from "./MainDressViewer4";
import MainDressViewer5 from "./MainDressViewer5";
import "./css/checkout.css";
import { UXLog } from '../lib/logger';
import { BASE_URL } from "../lib/common";
import { requestHandlerFunction } from "../lib/RequestHandler";
import MainDressViewer7 from './MainDressViewer7';
import MainDressViewer5Debug from './MainDressViewer5Debug';
import MainDressViewer5Debug1 from './MainDressViewer5Debug1';

function Checkout(props) {
    const storeName = props.shopValue;
    const dm = props.storeConfig.debugMode;

    // const [isCheckoutBoxHidden, setIsCheckoutBoxHidden] = useState(false);
    const [currency, setCurrency] = useState("");
    const [message, setMessage] = useState("");
    const [messageStyle, setMessageStyle] = useState({});

    const [upperProduct, setUpperProduct] = useState(null);
    const [lowerProduct, setLowerProduct] = useState(null);

    const [sizeVarUpper, setSizeVarUpper] = useState("");
    const [sizeVarLower, setSizeVarLower] = useState("");

    const [colorVarUpper, setColorVarUpper] = useState("");
    const [colorVarLower, setColorVarLower] = useState("");

    const [varAvailableUpper, setVarAvailableUpper] = useState("");
    const [varAvailableLower, setVarAvailableLower] = useState("");


    const [sbvPriceUpper, setSbvPriceUpper] = useState("");
    const [sbvPriceLower, setSbvPriceLower] = useState("");

    const [varIdUpper, setVarIdUpper] = useState("");
    const [varIdLower, setVarIdLower] = useState("");

    const [prodTitleUpper, setProdTitleUpper] = useState("");
    const [prodTitleLower, setProdTitleLower] = useState("");

    const [totalPriceVal, setTotalPriceVal] = useState("");
    const [addToCartDisabled, setAddToCartDisabled] = useState(false);

    const [highlightedColorDivsU, setHighlightedColorDivsU] = useState(['colorDivU0']);
    const [highlightedSizeDivsU, setHighlightedSizeDivsU] = useState(['sizeDivU0']);
    const [highlightedColorDivsL, setHighlightedColorDivsL] = useState(['colorDivL0']);
    const [highlightedSizeDivsL, setHighlightedSizeDivsL] = useState(['sizeDivL0']);

    const changeColorRef = useRef(null);
    const colorRefreshTimer = useRef(null);

    function set_curr_upper_ph_on_shopify(upper_ph) {
        dm && console.log("Entering - set_curr_upper_ph_on_shopify() - upper_ph=", upper_ph);
        if (!document.getElementById("sbv.mnm.curr_upper_ph")) {
            return;
        }
        let hidden_var = document.getElementById("sbv.mnm.curr_upper_ph");
        dm && console.log("set_curr_upper_ph_on_shopify - hidden_var=" + hidden_var);
        hidden_var.value = upper_ph;
        dm && console.log("set_curr_upper_ph_on_shopify - value set to =" + upper_ph);
        get_shopify_details(["", upper_ph]);
    }

    function set_curr_lower_ph_on_shopify(lower_ph) {
        dm && console.log("Entering - set_curr_lower_ph_on_shopify()");
        if (!document.getElementById("sbv.mnm.curr_lower_ph")) {
            return;
        }
        let hidden_var = document.getElementById("sbv.mnm.curr_lower_ph");
        dm && console.log("set_curr_lower_ph_on_shopify - hidden_var=" + hidden_var);
        hidden_var.value = lower_ph;
        dm && console.log("set_curr_lower_ph_on_shopify - value set to =" + lower_ph);
        get_shopify_details([lower_ph, ""]);
    }

    function checkAndCallUpdateProduct() {
        if ('updateProduct' in window) {
            dm && console.log("Calling updateProduct()");
            eval("updateProduct()");
        } else {
            dm && console.error("Function updateProduct() is not defined.");
        }
        return;
    }

    function getCurrency() {
        dm && console.log("Entering - getCurrency");
        // fetch(`${window.Shopify.routes.root}admin/shop.json`)
        //     .then(response => response.json())
        //     .then(data => {
        //         setCurrency(data.shop.currency);
        //         dm && console.log(".then getCurrency - " + data.shop.currency);
        //     })
        //     .catch(error => {
        //         dm && console.error(error);
        //     });
        setCurrency("INR");
    }

    function totalPrice() {
        let upperPrice = parseFloat(sbvPriceUpper);
        let lowerPrice = parseFloat(sbvPriceLower);
        let total = upperPrice + lowerPrice;
        setTotalPriceVal(total.toString());
    }

    function setVarProperty(product, isUpper, sizeVar, colorVar) {
        let currVar = "", altCurrVar = "";
        if (sizeVar && colorVar) {
            currVar = sizeVar + " / " + colorVar;
            altCurrVar = colorVar + " / " + sizeVar;
        }
        else if (sizeVar) currVar = sizeVar;
        else if (colorVar) currVar = colorVar;

        let varId, varPrice, varAvailable;

        product.variants.forEach((variant) => {
            if (variant.title === currVar || variant.title === altCurrVar) {
                varId = variant.id;
                varPrice = parseFloat(variant.price) / 100;
                varAvailable = variant.available;
            }
        });

        (isUpper) ? setVarAvailableUpper(varAvailable) : setVarAvailableLower(varAvailable);
        (isUpper) ? setSbvPriceUpper(varPrice.toString()) : setSbvPriceLower(varPrice.toString());
        (isUpper) ? setVarIdUpper(varId) : setVarIdLower(varId);
        dm && console.log("isUpper =", isUpper, " varId :", varId);
    }

    function populateVariant(e, isUpper) {
        let value = e.getAttribute("value");
        const topOrBottom = (isUpper) ? 1 : 2;

        let sizeVar = (isUpper) ? sizeVarUpper : sizeVarLower, colorVar = (isUpper) ? colorVarUpper : colorVarLower;

        if (e.className?.includes("colorDiv")) {
            changeColorRef.current.changeColor(value, topOrBottom);
            (isUpper) ? setColorVarUpper(value) : setColorVarLower(value);
            colorVar = value;
            (isUpper) ? setHighlightedColorDivsU([]) : setHighlightedColorDivsL([]);
            (isUpper) ? setHighlightedColorDivsU([e.id]) : setHighlightedColorDivsL([e.id]);
        }

        if (e.className?.includes("sizeDiv")) {
            (isUpper) ? setSizeVarUpper(value) : setSizeVarLower(value);
            sizeVar = value;
            (isUpper) ? setHighlightedSizeDivsU([]) : setHighlightedSizeDivsL([]);
            (isUpper) ? setHighlightedSizeDivsU([e.id]) : setHighlightedSizeDivsL([e.id]);
        }

        let product = (isUpper) ? upperProduct : lowerProduct;
        setVarProperty(product, isUpper, sizeVar, colorVar);
    }

    const getProduct = async (my_ph, isUpper) => {
        try {
            const response = await fetch(window.Shopify.routes.root + 'products/' + my_ph + '.js');
            const product = await response.json();
            dm && console.log(product);

            (isUpper) ? setUpperProduct(product) : setLowerProduct(product);

            if (product.variants && product.variants[0].title != "Default Title" && product.variants.length > 0) {
                const firstVariant = product.variants[0];
                let sizeVarExist = false, colorVarExist = false, newSizeVar = "", newColorVar = "";
                product.options.forEach((option) => {
                    if (option.name == "Size") {
                        newSizeVar = option.values[0];
                        if (option.values?.includes(isUpper ? sizeVarUpper : sizeVarLower)) { sizeVarExist = true; }
                    }
                    if (option.name == "Color") {
                        newColorVar = option.values[0];
                        if (option.values?.includes(isUpper ? colorVarUpper : colorVarLower)) { colorVarExist = true; }
                    }
                });

                if (isUpper) {
                    setSizeVarUpper((sizeVarUpper === "" || !sizeVarExist) ? newSizeVar : sizeVarUpper);
                    setColorVarUpper((colorVarUpper === "" || !colorVarExist) ? newColorVar : colorVarUpper);
                } else {
                    setSizeVarLower((sizeVarLower === "" || !sizeVarExist) ? newSizeVar : sizeVarLower);
                    setColorVarLower((colorVarLower === "" || !colorVarExist) ? newColorVar : colorVarLower);
                }

                let sizeVar = (isUpper) ? ((sizeVarUpper === "" || !sizeVarExist) ? newSizeVar : sizeVarUpper) : ((sizeVarLower === "" || !sizeVarExist) ? newSizeVar : sizeVarLower);
                let colorVar = (isUpper) ? ((colorVarUpper === "" || !colorVarExist) ? newColorVar : colorVarUpper) : ((colorVarLower === "" || !colorVarExist) ? newColorVar : colorVarLower);
                const topOrBottom = (isUpper) ? 1 : 2;
                if (colorVar !== "" && colorVar != null && colorVar !== undefined) changeColorRef.current.changeColor(colorVar, topOrBottom);
                setVarProperty(product, isUpper, sizeVar, colorVar);
            } else {
                (isUpper) ? setSbvPriceUpper((parseFloat(product.price) / 100).toString()) : setSbvPriceLower((parseFloat(product.price) / 100).toString());
            }
            (isUpper) ? setProdTitleUpper(product.title.toUpperCase()) : setProdTitleLower(product.title.toUpperCase());
            dm && console.log("SHOPIFY TESTING2 ================================================================================");
        } catch (error) {
            dm && console.error(error);
        }
    };

    function getDivPrefix(topOrBottom) {
        const hiddenElementName = topOrBottom === 1 ? "sbv.mnm.upper_color_div_id" : "sbv.mnm.lower_color_div_id";
        const iDElement = document.getElementById(hiddenElementName);
        let idPrefix = null;
        if (iDElement !== undefined && iDElement !== null && iDElement !== "") {
            idPrefix = iDElement.getAttribute("value");
        }
        return idPrefix;
    }


    function simulateClick(newColorCode, topOrBottom) {
        dm && console.log("Entering simulateClick() - newColorCode, topOrBottom: ", newColorCode, topOrBottom);
        const invColorCodes = getInverseColorCodes();
        if (invColorCodes === null) {
            console.error("simulateClick() - invColorCodes NOT FOUND, color not refreshed");
            UXLog(storeName, 'Checkout:RefreshColors invColorCodes NOT FOUND', 'ERROR');
            return;
        }
        const myColorName = invColorCodes[newColorCode];
        dm && console.log("myColorName - ", myColorName);
        const my_prefix = getDivPrefix(topOrBottom);
        if (my_prefix === null) {
            console.error("simulateClick() - prefix NOT FOUND");
            UXLog(storeName, 'Checkout:RefreshColors prefix NOT FOUND', 'ERROR');
            return;
        }
        let colorDomObj = null;
        for (let i = 0; i < Object.keys(invColorCodes).length; i++) {
            const nextElementID = my_prefix + i.toString();
            colorDomObj = getElement(nextElementID);
            if (colorDomObj === undefined || colorDomObj === null) {
                dm && console.log("simulateClick() - colorDomObj", colorDomObj);
                continue;
            }
            const colorDomObjValueOrig = colorDomObj.getAttribute("value");
            dm && console.log("colorDomObjValue - ", colorDomObjValueOrig);
            if (colorDomObj === undefined || colorDomObj === null || colorDomObjValueOrig === undefined || colorDomObjValueOrig === null) {
                dm && console.log("simulateClick() - colorDomObj, colorDomObjValueOrig: ", colorDomObj, colorDomObjValueOrig);
                continue;
            }
            const colorDomObjValue = colorDomObjValueOrig.toLowerCase();
            if (colorDomObjValue === myColorName || colorDomObjValue === newColorCode) {
                dm && console.log("simulateClick() - colorDomObj: ", colorDomObj);
                break;
            }
        }
        if (colorDomObj === undefined || colorDomObj === null || colorDomObj === "") {
            console.error("simulateClick() - colorDomObj NOT FOUND");
            UXLog(storeName, 'Checkout:RefreshColors colorDomObj NOT Found', 'ERROR');
            return;
        }
        var clickEvent = new MouseEvent('click', { bubbles: true, cancelable: true, view: window });
        colorDomObj.dispatchEvent(clickEvent);
    }

    function getElement(elementID) {
        let elementObj = null;
        if (props.storeConfig.isCheckoutBoxHidden) {
            elementObj = document.getElementById(elementID);
        } else {
            var shadowHost = document.querySelector('#sbv-mnm-div');
            elementObj = shadowHost.shadowRoot.querySelector("#" + elementID);
        }
        dm && console.log("getElement(): element = ", elementID, elementObj);
        return elementObj;
    }

    function getInverseColorCodes() {
        dm && console.log("Entering getInverseColorCodes()");
        let colorCodes;
        if (typeof getColorCodes === 'function') {
            colorCodes = eval("getColorCodes()");
        } else {
            console.error("getColorCodes() is not defined");
            return null;
        }

        dm && console.log("getInverseColorCodes(): ColorCodes = ", colorCodes);
        if (colorCodes === undefined || colorCodes === null || colorCodes === "") {
            console.error("getColorCodes() returned NULL")
            return null;
        }
        const inverseColorCodes = {}
        const colorNames = Object.keys(colorCodes);
        for (let i = 0; i < colorNames.length; i++) {
            let myColorName = colorNames[i];
            const myColorCode = (colorCodes[myColorName]).toLowerCase();
            myColorName = myColorName.toLowerCase();
            inverseColorCodes[myColorCode] = myColorName;
        }
        dm && console.log("getInverseColorCodes(): inverseColorCodes = ", inverseColorCodes);
        return inverseColorCodes;
    }


    function get_shopify_details(phs) {
        // dm && console.log("Entering get_shopify_details, isCheckoutBoxHidden=", isCheckoutBoxHidden);
        dm && console.log("Entering get_shopify_details, props.storeConfig.isCheckoutBoxHidden=", props.storeConfig.isCheckoutBoxHidden);
        if (props.storeConfig.isCheckoutBoxHidden) {
            checkAndCallUpdateProduct();
            checkAndInitiateRefresh();
            return;
        }
        const lower_ph = phs[0];
        const upper_ph = phs[1];
        dm && console.log("lower_ph = " + lower_ph);
        dm && console.log("upper_ph = " + upper_ph);

        let my_ph = upper_ph;
        if (lower_ph !== "") {
            my_ph = lower_ph;
        }

        let isUpper = (lower_ph !== "" ? 0 : 1);
        getProduct(my_ph, isUpper);
        checkAndInitiateRefresh();
    }

    function checkAndInitiateRefresh() {
        if (colorRefreshTimer.current !== null) {
            return;
        }
        const refreshStartedSess = sessionStorage.getItem("refreshStartedSess");
        if (refreshStartedSess === undefined || refreshStartedSess === null || refreshStartedSess === "" || refreshStartedSess === "0") {
            return;
        }
        const topColor = sessionStorage.getItem('topColor');
        const bottomColor = sessionStorage.getItem('bottomColor');
        if ((topColor === undefined || topColor === null || topColor === "") &&
            (bottomColor === undefined || bottomColor === null || bottomColor === "")) {
            return;
        }

        dm && console.log("setting - colorRefreshTimer");
        colorRefreshTimer.current = setTimeout(() => {
            refreshColors();
        }, 3000);
        dm && console.log("colorRefreshTimer.current = ", colorRefreshTimer.current);
    }

    function refreshColors() {
        dm && console.log("Entering refreshColors()");
        let newColor = sessionStorage.getItem('topColor');
        dm && console.log("refreshColors() - topColor: ", newColor);
        if (newColor !== undefined && newColor !== null && newColor !== "") {
            let newColorLower = newColor.toLowerCase();
            simulateClick(newColorLower, 1);
        }

        newColor = sessionStorage.getItem('bottomColor');
        dm && console.log("get_shopify_details() - bottomColor: ", newColor);
        if (newColor !== undefined && newColor !== null && newColor !== "") {
            let newColorLower = newColor.toLowerCase();
            simulateClick(newColorLower, 2);
        }
        dm && console.log("refreshColors() - setting - refreshStartedSess to 0");
        sessionStorage.setItem("refreshStartedSess", "0");
    }

    function addToCart(varIdU, varIdL) {
        UXLog(storeName, 'Checkout:AddToCart', 'AddToCart', 'Upper: ' + varIdU + ', Lower: ' + varIdL);
        let formData = {
            'items': [{ 'id': varIdU, 'quantity': 1, 'properties': { 'application': 'SBV-Mix_And_Match' } },
            { 'id': varIdL, 'quantity': 1, 'properties': { 'application': 'SBV-Mix_And_Match' } }]
        };

        fetch(window.Shopify.routes.root + 'cart/add.js', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData)
        })
            .then(response => {
                if (response.status === 200) {
                    setMessage("Items Successfully Added To Cart");
                    setMessageStyle({ display: "block", backgroundColor: "lightgreen" });
                    setTimeout(() => {
                        setMessage("");
                        setMessageStyle({ display: "none" });
                    }, 5000);
                } else {
                    setMessage("Item is out of stock. Cannot add to cart.");
                    setMessageStyle({ display: "block", backgroundColor: "lightcoral" });
                    setTimeout(() => {
                        setMessage("");
                        setMessageStyle({ display: "none" });
                    }, 5000);
                }
                return response.json();
            })
            .catch((error) => {
                setMessage("Something Wrong Happens While Adding Items To Cart !");
                setMessageStyle({ display: "block", backgroundColor: "lightcoral" });
                setTimeout(() => {
                    setMessage("");
                    setMessageStyle({ display: "none" });
                }, 5000);
                dm && console.error('Error:', error);
            });
    }

    function updateAddToCartButton() {
        if (varAvailableUpper && varAvailableLower) {
            setAddToCartDisabled(false);
        } else {
            setAddToCartDisabled(true);
        }
    }

    useEffect(() => {
        dm && console.log("in useEffect of Checkout");
        UXLog(storeName, 'Broswer:', navigator.userAgent);
        getCurrency();
    }, []);

    useEffect(() => {
        totalPrice();
    }, [sbvPriceUpper, sbvPriceLower]);


    useEffect(() => {
        updateAddToCartButton();
    }, [varAvailableUpper, varAvailableLower]);

    // const getCheckoutBoxStatus = async () => {
    //     dm && console.log("StoreConfig - isCheckoutBoxHidden: ", props.storeConfig.isCheckoutBoxHidden);
    //     setIsCheckoutBoxHidden(props.storeConfig.isCheckoutBoxHidden);
    // };

    // useEffect(() => {
    //     getCheckoutBoxStatus();
    // }, []);


    return (
        <>
            <div id="sbv.message" className="msgDiv" style={messageStyle}>{message}</div>
            <div className="container">
                <div className="section1">

            {(props.shopValue != 'sbv-show.myshopify.com' && props.shopValue != 'style-by-vida-dev.myshopify.com') && <MainDressViewer5Debug1
                        setLowerPHFunction={set_curr_lower_ph_on_shopify} setUpperPHFunction={set_curr_upper_ph_on_shopify}
                        shopValue={props.shopValue} ref={changeColorRef} dm={props.storeConfig.debugMode} storeConfig={props.storeConfig} planExpired={props.planExpired}/>}
      {(props.shopValue == 'sbv-show.myshopify.com' || props.shopValue == 'style-by-vida-dev.myshopify.com') && <MainDressViewer7
                        setLowerPHFunction={set_curr_lower_ph_on_shopify} setUpperPHFunction={set_curr_upper_ph_on_shopify}
                        shopValue={props.shopValue} ref={changeColorRef} dm={props.storeConfig.debugMode} storeConfig={props.storeConfig} planExpired={props.planExpired}/>}
                    {/* {<MainDressViewer5
                        setLowerPHFunction={set_curr_lower_ph_on_shopify} setUpperPHFunction={set_curr_upper_ph_on_shopify}
                        shopValue={props.shopValue} ref={changeColorRef} dm={props.storeConfig.debugMode} storeConfig={props.storeConfig} />} */}
                    {/* {<MainDressViewer4
                        setLowerPHFunction={set_curr_lower_ph_on_shopify} setUpperPHFunction={set_curr_upper_ph_on_shopify}
                        shopValue={props.shopValue} ref={changeColorRef} dm={props.storeConfig.debugMode} storeConfig={props.storeConfig} />} */}
                </div>
                <div className="section2">
                    {!props.storeConfig.isCheckoutBoxHidden &&
                        (<div className="checkout" id="sbv.checkout">
                            <div className="upperDiv">
                                <h1 id="sbv.title.upper" className="productTitle">{prodTitleUpper}</h1>
                                <div id="sbv.varId.upper" style={{ display: "none" }}>{varIdUpper}</div>
                                <div id="sbv.price.upper" className="priceDiv">{`${currency}. ${sbvPriceUpper}`}</div>
                                <div id="sbv.colors.upper" className="varDivs">
                                    {upperProduct?.options.filter(option => option.name === 'Color').map((option) => (
                                        <>
                                            <div key={option.name} className='optTitle'>COLOR</div>
                                            {option.values.map((color, index) => (
                                                <div
                                                    key={`colorDivU${index}`}
                                                    id={`colorDivU${index}`}
                                                    className={highlightedColorDivsU.includes(`colorDivU${index}`) ? 'colorDivU highlight' : 'colorDivU'}
                                                    style={{ backgroundColor: color }}
                                                    value={color}
                                                    onClick={(event) => populateVariant(event.target, 1)}
                                                ></div>
                                            ))}
                                        </>
                                    ))}

                                </div>
                                <div id="sbv.sizes.upper" className="varDivs">
                                    {upperProduct?.options.filter(option => option.name === 'Size').map((option) => (
                                        <>
                                            <div key={option.name} className='optTitle'>SIZE</div>
                                            {option.values.map((size, index) => (
                                                <div
                                                    key={`sizeDivU${index}`}
                                                    id={`sizeDivU${index}`}
                                                    className={highlightedSizeDivsU.includes(`sizeDivU${index}`) ? 'sizeDivU highlight' : 'sizeDivU'}
                                                    value={size}
                                                    onClick={(event) => populateVariant(event.target, 1)}
                                                >
                                                    {size}
                                                </div>
                                            ))}
                                        </>
                                    ))}

                                </div>
                            </div>
                            <hr style={{ margin: "10px 0px" }} />
                            <div className="lowerDiv">
                                <h1 id="sbv.title.lower" className="productTitle">{prodTitleLower}</h1>
                                <div id="sbv.varId.lower" style={{ display: "none" }}>{varIdLower}</div>
                                <div id="sbv.price.lower" className="priceDiv">{`${currency}.${sbvPriceLower}`}</div>
                                <div id="sbv.colors.lower" className="varDivs">
                                    {lowerProduct?.options.filter(option => option.name === 'Color').map((option) => (
                                        <>
                                            <div key={option.name} className='optTitle'>COLOR</div>
                                            {option.values.map((color, index) => (
                                                <div
                                                    key={`colorDivL${index}`}
                                                    id={`colorDivL${index}`}
                                                    className={highlightedColorDivsL.includes(`colorDivL${index}`) ? 'colorDivL highlight' : 'colorDivL'}
                                                    style={{ backgroundColor: color }}
                                                    value={color}
                                                    onClick={(event) => populateVariant(event.target, 0)}
                                                ></div>
                                            ))}
                                        </>
                                    ))}

                                </div>
                                <div id="sbv.sizes.lower" className="varDivs">
                                    {lowerProduct?.options.filter(option => option.name === 'Size').map((option) => (
                                        <>
                                            <div key={option.name} className='optTitle'>SIZE</div>
                                            {option.values.map((size, index) => (
                                                <div
                                                    key={`sizeDivL${index}`}
                                                    id={`sizeDivL${index}`}
                                                    className={highlightedSizeDivsL.includes(`sizeDivL${index}`) ? 'sizeDivL highlight' : 'sizeDivL'}
                                                    value={size}
                                                    onClick={(event) => populateVariant(event.target, 0)}
                                                >
                                                    {size}
                                                </div>
                                            ))}
                                        </>
                                    ))}

                                </div>
                            </div>
                            <hr style={{ margin: "10px 0px" }} />
                            <div>
                                <div id="sbv.price.total" className="priceDiv">{`TOTAL : ${currency}. ${totalPriceVal}`}</div>
                                <button id="addToCartBtn" onClick={() => addToCart(varIdUpper, varIdLower)} className={(addToCartDisabled ? "btn disabled" : "btn")} >ADD TO CART</button>
                            </div>
                        </div>)
                    }
                </div>
            </div>
        </>
    )
}

export default Checkout;