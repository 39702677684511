export function disposeCurrentItem(gltf) {
    const toRemoveChildren = [];
    if (gltf && gltf?.scene && gltf?.scene?.traverse) {
      gltf.scene.traverse((child) => {
        if (child.type === "Mesh") {
          child?.geometry?.dispose();
          child?.material?.dispose();
        }
        toRemoveChildren.push(child);
      });
  
      toRemoveChildren.forEach((child) => {
        gltf?.scene?.remove(child);
      });
    }
  }