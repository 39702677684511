import axios from "axios";
import logger from "./logger";

let requestList = []; //List that holds all requests
let reqSeqNo = 0;
const progName = "RequestHandler";

const addRequestToList = (url, requestType, payload) => {
  const myData = {
    url: url,
    requestType: requestType,
    payload: payload
  };
  requestList.push(myData);
  return myData;
};

const removeRequestFromList = (myData) => {
  // logger("in removeRequestFromList Before requestList.length=" + requestList.length, "RequestHandler");
  requestList = requestList.filter((request) => JSON.stringify(request) !== JSON.stringify(myData));
  // logger("in removeRequestFromList After requestList.length=" + requestList.length, "RequestHandler");
};

function preparePayload(requestPurpose, responseType, payload = {}) {
  payload["requestPurpose"] = requestPurpose;
  payload["responseType"] = responseType;
  payload["devname"] = "shopify-react-plugin";
  // logger(JSON.stringify(payload), 'RequestHandler:preparePayload');
  return payload;
}

function prepareURL(url, requestType, payload) {
  let new_url = url;
  if (requestType === "get") {
    new_url = url + "?";
    Object.keys(payload).forEach((key) => {
      new_url = new_url + key + "=" + payload[key] + "&";
      // logger("prepareURL:" + new_url + " - key=" + key, progName);
    });
  }
  logger("Final URL: " + new_url, progName);
  return new_url;
}

export const requestHandlerFunction = (url, requestPurpose, requestType, responseType, payload, debugMode = false) => {
  logger("Entering requestHandlerFunction: for " + requestPurpose +
    ", requestList.length=" + requestList.length, "=======> RequestHandler");
  let myreqNo = reqSeqNo + 1;
  reqSeqNo += 1;

  let userId = localStorage.getItem(`SBV_UserID_${payload["store_name"]}`);
  payload["userId"] = userId;
  
  let inp_payload = JSON.stringify(payload);
  const toContinue = true;
  if (!toContinue) {
    return new Promise(function (resolve, reject) {
      const myErr = { "response": { "status": 470, "data": { "MSG": "Duplicate Request" } } };
      reject(myErr)
    });
  }

  let dataAdded = addRequestToList(url, requestType, inp_payload);
  let new_payload = preparePayload(requestPurpose, responseType, payload);
  url = prepareURL(url, requestType, new_payload);
  if (requestType === "get") {
    // logger("Request being Submitted for " + requestPurpose, "RequestHandler");
    return new Promise(function (resolve, reject) {
      axios
        .get(url, new_payload)
        .then(function (response) {
          logger("SUCCESS in .get.then + myreqNo=" + myreqNo, "RequestHandler:requestHandlerFunction");
          resolve([response, myreqNo]);
        })
        .catch(function (error) {
          logger(("Error: Cannot execute " + requestPurpose), progName);
          logger(error, progName);
          reject(error);
        })
        .finally(function () {
          // logger("Finally Block url=" + url, progName);
          // logger("Finally Block, removing request from list myreqNo=" + myreqNo, progName);
          removeRequestFromList(dataAdded);
        });
    });
  } else if (requestType === "post") {
    return new Promise(function (resolve, reject) {
      axios
        .post(url, payload)
        .then(function (response) {
          resolve(response);
        })
        .catch(function (error) {
          logger(("Error: Cannot execute " + requestPurpose), 'RequestHandler');
          logger(error, 'RequestHandler');
          reject(error);
        }).finally(function () {
          removeRequestFromList(dataAdded);
        });
    });
  } else {
    logger("ERR: Request Type not supported", 'RequestHandler');
  }
};
