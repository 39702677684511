import React, { useState, Suspense, useRef, useEffect } from "react";
import { Canvas, useFrame, useThree } from "@react-three/fiber";
import { OrbitControls, Html, useGLTF, useProgress, PresentationControls } from "@react-three/drei";
import "./css/MDV_IMG_BTN.css";

import { requestHandlerFunction } from "../lib/RequestHandler";
import { BASE_URL, IS_LOCAL } from "../lib/common";
import logger from "../lib/logger";
import DressGlbSingleton from "../lib/DressGlbSingleton";
import { UXLog } from "../lib/logger";

// import harbor_hdri from "../assets/hdri/Harbor_Env.hdr";
// import Light_4_hdri from "../assets/hdri/Light_4.hdr";
// import studio_medium_contrast_hdri from "../assets/hdri/Medium_Contrast.hdr";
// import natural_2_hdri from "../assets/hdri/natural_2.hdr";
// import sky_hdri from "../assets/hdri/Sky_Cloudy_Env.hdr";

// import glbLoading from "./../assets/dresses/Loading.glb";
import glbLoadingTOP from "./../assets/dresses/LoadingTOP.glb";
import glbLoadingBOTTOM from "./../assets/dresses/LoadingBOTTOM.glb";

import right_arrow_svg from "./../assets/icons/single-arrow-right.svg";
import left_arrow_svg from "./../assets/icons/single-arrow-left.svg";
import intial_loading from "./../assets/icons/initial-loading-gif.gif";
import icon_360 from "./../assets/icons/360Icon.svg";
import icon_zoom from "./../assets/icons/zoomIcon.svg";
import button_avatar_off from "./../assets/icons/avatarOff.svg";
import button_avatar_on from "./../assets/icons/avatarOn.svg";
import button_reset from "./../assets/icons/reset-button.svg";
import icon_helper from "./../assets/icons/helper/helper-icon.svg";
import nav_button_helper from "./../assets/icons/helper/helper-navButton.svg";
import reset_helper from "./../assets/icons/helper/helper-reset.svg";
import rotate_helper from "./../assets/icons/helper/helper-rotate.svg";
import toggle_avatar_helper from "./../assets/icons/helper/helper-toggleAvatar.svg";
import zoom_helper from "./../assets/icons/helper/helper-zoom.svg";
import proceed_helper from "./../assets/icons/helper/helper-proceed.svg";
import download_icon from "./../assets/icons/download_icon.svg";

import { DirectionalLight, DirectionalLightHelper, DoubleSide, SpotLight, SpotLightHelper } from "three";
import Feedback from "./Feedback";


const MainDressViewer7 = React.forwardRef((props, ref) => {

  const isBlinking = useRef(false);
  const [isBlinkingState, setIsBlinkingState] = useState(false);
  const firstTimeBlinking = useRef(false);
  const rotationState = useRef(1);

  const [showFeedback, setShowFeedback] = useState(false);
  const [loadingStartTimeTop, setLoadingStartTimeTop] = useState(null);
  const [loadingStartTimeBottom, setLoadingStartTimeBottom] = useState(null);
  const effectRan = useRef(false);
  const firstTime1t = useRef(true);
  const firstTime2t = useRef(true);
  const firstTime1b = useRef(true);
  const firstTime2b = useRef(true);

  const changeColorAssigned = useRef(false);

  const [isPressed, setIsPressed] = useState({});

  const [colorVarTop, setColorVarTop] = useState("");
  const [colorVarBottom, setColorVarBottom] = useState("");

  const [topDownloadSeq, setTopDownloadSeq] = useState(0);
  const [bottomDownloadSeq, setBottomDownloadSeq] = useState(0);

  const [isDragging, setIsDragging] = useState(false);
  const isMoved = useRef(false)


  const timerIdsTop = useRef([]);
  const timerIdsBottom = useRef([]);
  const blinkingTimerId = useRef([]);

  // const loadingGlb = BASE_URL + "/static/media/Loading.glb";
  const loadingGlbTOP = BASE_URL + "/static/media/LoadingTOP.glb";
  const loadingGlbBOTTOM = BASE_URL + "/static/media/LoadingBOTTOM.glb";

  const left_button = BASE_URL + "/static/media/single-arrow-left.svg";
  const right_button = BASE_URL + "/static/media/single-arrow-right.svg";
  const initial_loading_gif = BASE_URL + "/static/media/initial-loading-gif.gif";
  // const threeDIcon = BASE_URL + "/static/media/360Icon.svg";
  // const zoomIcon = BASE_URL + "/static/media/zoomIcon.svg";
  const avatarOnToggleSvg = BASE_URL + "/static/media/avatarOn.svg";
  const avatarOffToggleSvg = BASE_URL + "/static/media/avatarOff.svg";
  const reset_button = BASE_URL + "/static/media/reset-button.svg";
  const helper_nav_button = BASE_URL + "/static/media/helper-navButton.svg";
  const helper_reset = BASE_URL + "/static/media/helper-reset.svg";
  const helper_rotate = BASE_URL + "/static/media/helper-rotate.svg";
  const helper_toggleAvatar = BASE_URL + "/static/media/helper-toggleAvatar.svg";
  const helper_zoom = BASE_URL + "/static/media/helper-zoom.svg";
  const helper_icon = BASE_URL + "/static/media/helper-icon.svg";
  const helper_proceed = BASE_URL + "/static/media/helper-proceed.svg";
  const download_icon_svg = BASE_URL + "/static/media/download_icon.svg";

  const [dressNoTop, setDressNoTop] = useState(0);
  const [dressNoBottom, setDressNoBottom] = useState(0);
  const topFileUrls = useRef({});
  const bottomFileUrls = useRef({});
  const [currTopUrl, setCurrTopUrl] = useState(null);
  const [currBottomUrl, setCurrBottomUrl] = useState(null);
  const listTopPHs = useRef([]);
  const listBottomPHs = useRef([]);
  const orbitControlsRef = useRef();
  const [showHelper, setShowHelper] = useState(false);

  const [showLoadingGif, setShowLoadingGif] = useState(true);

  const [downloadStatusTop, setDownloadStatusTop] = useState(0); // 0 - Not started yet, 1 - Download in Progress, 2 - Download Completed
  const [downloadStatusBottom, setDownloadStatusBottom] = useState(0); // 0 - Not started yet, 1 - Download in Progress, 2 - Download Completed

  const [topPrevImgUrl, setTopPrevImgUrl] = useState(null);
  const [topNextImgUrl, setTopNextImgUrl] = useState(null);
  const [bottomPrevImgUrl, setBottomPrevImgUrl] = useState(null);
  const [bottomNextImgUrl, setBottomNextImgUrl] = useState(null);

  const [avatarUrl, setAvatarUrl] = useState(null);
  const [toggleAvatar, setToggleAvatar] = useState(false);
  const currGltfTop = useRef(null);
  const currGltfBottom = useRef(null);
  const prevGltfTop = useRef(null);
  const prevGltfBottom = useRef(null);

  const currTopPhRef = useRef();
  const currBottomPhRef = useRef();
  const phGltfTopsRef = useRef({});
  const phGltfBottomsRef = useRef({});

  const storeName = props.shopValue;
  const topCollectionName = 'SBV-3D-A-Upper';
  const bottomCollectionName = 'SBV-3D-B-Lower';
  // const time_step = 0.0025; // Alternate rotations for 3d avatar & garments
  const time_step = 0.002; // Alternate rotations for 3d avatar & garments
  const rotation_angle = 0.3; //Angle of rotation for 3d models

  const meshGl = useRef(null);
  const meshCamera = useRef(null);
  const meshScene = useRef(null);

  // const envObj = {
  //   "Sky": { hdri: sky_hdri, exp: 0.6, env_name: "Sky", fileName: 'Sky_Cloudy_Env.hdr' },
  //   "Harbour": { hdri: harbor_hdri, exp: 2, env_name: "Harbour", fileName: 'Harbor_Env.hdr' },
  //   "Natural": { hdri: natural_2_hdri, exp: 1, env_name: "Natural", fileName: 'natural_2.hdr' },
  //   "Studio Lights": { hdri: Light_4_hdri, exp: 0.35, env_name: "Studio Lights", fileName: 'Light_4.hdr' },
  //   "Medium Contrast": { hdri: studio_medium_contrast_hdri, exp: 0.35, env_name: "Medium Contrast", fileName: 'Medium_Contrast.hdr' },
  // };

  const sbv_mnm = { apis: { changeDressColor: changeColor } };

  React.useImperativeHandle(ref, () => ({
    changeColor,
  }));

  function disposeCurrentItem(phToDispose, topOrBottom) {
    const myGltf = topOrBottom === 1 ? phGltfTopsRef.current[phToDispose] : phGltfBottomsRef.current[phToDispose];
    if (myGltf) {
      myGltf.scene.traverse((child) => {
        if (child.type === 'Mesh') {
          child.geometry.dispose();
          child.material.dispose();
          if (topOrBottom === 1) delete phGltfTopsRef.current[phToDispose];
          if (topOrBottom === 2) delete phGltfBottomsRef.current[phToDispose];
          props.dm && console.log('Disposed---');
        }
        myGltf.scene.remove(child)
      });
    }
  }

  function NextdressTop(stepsToMove = 1) {
    props.dm && console.log("Next Top - Button Pressed.", stepsToMove)
    let newNo;
    if (dressNoTop !== listTopPHs.current.length - 1) {
      newNo = dressNoTop + stepsToMove;
      setDressNoTop(prevNo => prevNo + stepsToMove);
    } else {
      newNo = 0
      setDressNoTop(0);
    }
    sessionStorage.setItem('dressNoTopSess', newNo);
    if (!IS_LOCAL) props.setUpperPHFunction(listTopPHs.current[newNo]);
    currTopPhRef.current = listTopPHs.current[newNo];
    setCurrTopUrl(topFileUrls.current[listTopPHs.current[newNo]]);
    UXLog(storeName, 'MainDressViewer:NextTopButton', listTopPHs.current[newNo]);
    incrementIntCount();
  }

  function PreviousdressTop() {
    props.dm && console.log("Prev Top - Button Pressed.")
    let newNo;
    if (dressNoTop !== 0) {
      newNo = dressNoTop - 1;
      setDressNoTop(prevNo => prevNo - 1);
    } else {
      newNo = listTopPHs.current.length - 1;
      setDressNoTop(listTopPHs.current.length - 1);
    }
    sessionStorage.setItem('dressNoTopSess', newNo);
    if (!IS_LOCAL) props.setUpperPHFunction(listTopPHs.current[newNo]);
    setCurrTopUrl(topFileUrls.current[listTopPHs.current[newNo]]);
    currTopPhRef.current = listTopPHs.current[newNo];
    UXLog(storeName, 'MainDressViewer:PrevTopButton', listTopPHs.current[newNo]);
    incrementIntCount();
  }

  function NextdressBottom(stepsToMove = 1) {
    props.dm && console.log("Next Bottom - Button Pressed.", stepsToMove)
    let newNo;
    if (dressNoBottom !== listBottomPHs.current.length - 1) {
      newNo = dressNoBottom + stepsToMove;
      setDressNoBottom(prevNo => prevNo + stepsToMove);
    } else {
      newNo = 0;
      setDressNoBottom(0);
    }
    sessionStorage.setItem('dressNoBottomSess', newNo);
    if (!IS_LOCAL) props.setLowerPHFunction(listBottomPHs.current[newNo]);
    setCurrBottomUrl(bottomFileUrls.current[listBottomPHs.current[newNo]]);
    currBottomPhRef.current = listBottomPHs.current[newNo];
    UXLog(storeName, 'MainDressViewer:NextBottomButton', listBottomPHs.current[newNo]);
    incrementIntCount();
  }

  function PreviousdressBottom() {
    props.dm && console.log("Prev Bottom - Button Pressed.")
    let newNo;
    if (dressNoBottom !== 0) {
      newNo = dressNoBottom - 1;
      setDressNoBottom(prevNo => prevNo - 1);
    } else {
      newNo = listBottomPHs.current.length - 1;
      setDressNoBottom(listBottomPHs.current.length - 1);
    }
    sessionStorage.setItem('dressNoBottomSess', newNo);
    if (!IS_LOCAL) props.setLowerPHFunction(listBottomPHs.current[newNo]);
    setCurrBottomUrl(bottomFileUrls.current[listBottomPHs.current[newNo]]);
    currBottomPhRef.current = listBottomPHs.current[newNo];
    UXLog(storeName, 'MainDressViewer:PrevBottomButton', listBottomPHs.current[newNo]);
    incrementIntCount();
  }
  function avatarToggle() {
    if (toggleAvatar === false) UXLog(storeName, 'MainDressViewer:AvatarToggle', 'AvatarToggleButtonOn');
    else UXLog(storeName, 'MainDressViewer:AvatarToggle', 'AvatarToggleButtonOff');
    if (toggleAvatar === false) setToggleAvatar(true);
    else setToggleAvatar(false);
    incrementIntCount();
    restartTimer("avatarToggle");
  }

  function changeColor(targetColorCode, topOrBottom) {
    props.dm && console.log("Entering changeColor for topOrBottom=" + topOrBottom + ": targetColorCode", targetColorCode);
    props.dm && console.log("changeColor - typeof (targetColorCode)=", typeof (targetColorCode));

    const myTargetColor = topOrBottom === 1 ? colorVarTop : colorVarBottom;
    const ux_value = topOrBottom === 1 ? listTopPHs.current[dressNoTop] : listBottomPHs.current[dressNoBottom];
    const misc_info = topOrBottom === 1 ? "top" : "bottom";
    if (targetColorCode !== myTargetColor) {
      UXLog(storeName, 'MainDressViewer:changeColor', targetColorCode, ux_value, misc_info);
      restartTimer("changeColor");
      // incrementIntCount();
    }
    (topOrBottom === 1) ? setColorVarTop(targetColorCode) : setColorVarBottom(targetColorCode);
  }

  function changeColorAgain(topOrBottom) {
    const myTargetColor = topOrBottom === 1 ? colorVarTop : colorVarBottom;
    if (myTargetColor !== "") {
      changeColorInDress(myTargetColor, topOrBottom);
    }
  }

  function changeColorInDress(targetColorCode, topOrBottom) {
    const refreshStartedSess = sessionStorage.getItem("refreshStartedSess");
    if ((refreshStartedSess === undefined || refreshStartedSess === null || refreshStartedSess === ""
      || refreshStartedSess === "0")) {
      const colorKey = topOrBottom === 1 ? "topColor" : "bottomColor";
      sessionStorage.setItem(colorKey, targetColorCode);
    }
    const dontChangeList = new Set();
    topOrBottom === 1 ? setColorVarTop(targetColorCode) : setColorVarBottom(targetColorCode);
    const myGltf = (topOrBottom === 1) ? currGltfTop : currGltfBottom;
    myGltf.current.scene.traverse((child) => {
      if (child.name !== null && child.name !== undefined && (child.name.startsWith('Trim') || child.name.startsWith('MatShape'))) {
        if (child.isMesh) {
          dontChangeList.add(child.name);
        }
        if (child.children.length !== 0 && child.children[0].name !== null
          && child.children[0].name !== undefined) {
          dontChangeList.add(child.children[0].name);
        }
      }
      if (child.isMesh && !dontChangeList.has(child.name)) {
        child.material.color.set(targetColorCode);
      }
    });
  }

  const fetchAvatar = () => {
    return new Promise(function (resolve, reject) {
      const url = BASE_URL + "/getAvatar";
      const myData = {
        store_name: storeName,
      };
      requestHandlerFunction(url, "getAvatar", "get", "arraybuffer", myData)
        .then(function (r) {
          const response = r[0];
          const file = new Blob([response.data], { type: "model/gltf+binary" });
          const fileUrl = URL.createObjectURL(file);
          setAvatarUrl(fileUrl);
          useGLTF.preload(fileUrl);
          props.dm && console.log("Avatar URL: " + fileUrl);
          props.dm && console.log("Avatar: typeof fileUrl" + typeof fileUrl);
          avatarToggle();
          resolve();
        })
        .catch(function (error) {
          props.dm && console.log("Error: Avatar not loaded...!!!");
          reject();
        });
    });
  };

  const fetchDress = (collectionName, productHandle) => {
    //Function to fetch Dress GLB from server through RequestHandler.js
    return new Promise(function (resolve, reject) {
      const url = BASE_URL + "/get3DModel";
      const myData = {
        store_name: storeName,
        collection_name: collectionName,
        product_handle: productHandle,
      };
      requestHandlerFunction(url, "get3DModel", "get", "arraybuffer", myData)
        .then(function (resp) {
          resolve(resp);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };

  function downloadGarment(collectionName, productHandle, fileUrls, topOrBottom) {
    props.dm && console.log("Entering downloadGarment(): productHandle=" + productHandle + ", topOrBottom=" + topOrBottom);
    //Function to run the fetchDress function and store the garment file and extract its URL
    topOrBottom === 1 ? setDownloadStatusTop(1) : setDownloadStatusBottom(1);
    return new Promise(function (resolve, reject) {
      DressGlbSingleton.setFile(productHandle, "Downloading", topOrBottom);
      fetchDress(collectionName, productHandle)
        .then(function (r) {
          const response = r[0];
          const request_id = r[1];
          const file = new Blob([response.data], { type: "model/gltf+binary" });
          DressGlbSingleton.setFile(productHandle, file, topOrBottom);
          let tempUrl = URL.createObjectURL(DressGlbSingleton.getFile(productHandle, topOrBottom));
          props.dm && console.log("downloadGarment(): DONE - productHandle = " + productHandle + ", tempUrl=" + tempUrl + ", topOrBottom=" + topOrBottom);
          fileUrls.current[productHandle] = tempUrl;
          props.dm && console.log(fileUrls.current);
          topOrBottom === 1 ? setDownloadStatusTop(2) : setDownloadStatusBottom(2);
          resolve(tempUrl);
        })
        .catch(function (error) {
          topOrBottom === 1 ? setDownloadStatusTop(0) : setDownloadStatusBottom(0);
          DressGlbSingleton.downloadFailed(productHandle, topOrBottom);
          props.dm && console.log("catch block");
          props.dm && console.log(error);
          if (error.response.status === 470) {
            logger("Duplicate Request not Submitted.", "Dressinfo");
          } else {
            logger(error, "Dressinfo-fetchDress-error");
            logger("Dress not downloaded", "Dressinfo");
          }
          reject();
        });
    });
  }

  function checkAndDownload(collectionName, fileUrls, dressIndex, topOrBottom) {
    props.dm && console.log("Entering checkAndDownload(): dressIndex=", dressIndex, "topOrBottom=", topOrBottom);
    let phToDownload = topOrBottom === 1 ? listTopPHs.current[dressIndex] : listBottomPHs.current[dressIndex];
    if (!DressGlbSingleton.checkFileNameExists(phToDownload)) {
      console.log("checkAndDownload() ", "starting download");
      downloadGarment(collectionName, phToDownload, fileUrls, topOrBottom).then((url) => {
        useGLTF.preload(fileUrls.current[phToDownload]);
        topOrBottom === 1 ? downloadNextTop() : downloadNextBottom();
      }).catch(function (error) { props.dm && console.log(error) });
      return true;
    } else {
      return false;
    }
  }

  function downloadNextTop() {
    props.dm && console.log("Entering - downloadNextTop(): - dressNoTop=" + dressNoTop + ", topDownloadSeq=" + topDownloadSeq);
    props.dm && console.log("DBT DressGlbSingleton.getCancelSeqNo(1)=" + DressGlbSingleton.getCancelSeqNo(1));
    if (topDownloadSeq < DressGlbSingleton.getCancelSeqNo(1)) {
      props.dm && console.log("Stopping current download sequence of downloadNextTop, topDownloadSeq=" + topDownloadSeq)
      return;
    }
    let nextIndex = dressNoTop;
    if (checkAndDownload(topCollectionName, topFileUrls, nextIndex, 1)) return;

    nextIndex = (dressNoTop + 1) % listTopPHs.current.length;
    if (checkAndDownload(topCollectionName, topFileUrls, nextIndex, 1)) return;

    nextIndex = dressNoTop - 1;
    if (nextIndex < 0) nextIndex = nextIndex + listTopPHs.current.length;
    if (checkAndDownload(topCollectionName, topFileUrls, nextIndex, 1)) return;

    for (let i = dressNoTop + 2; i <= dressNoTop + props.storeConfig.loadNextNum; i++) {
      nextIndex = i % listTopPHs.current.length;
      if (checkAndDownload(topCollectionName, topFileUrls, nextIndex, 1)) return;
    }
    for (let i = dressNoTop - 2; i >= dressNoTop - props.storeConfig.loadPrevNum; i--) {
      nextIndex = i % listTopPHs.current.length;
      if (nextIndex < 0) nextIndex += listTopPHs.current.length;
      if (checkAndDownload(topCollectionName, topFileUrls, nextIndex, 1)) return;
    }
    if (avatarUrl === null) fetchAvatar();
  }

  function downloadNextBottom() {
    props.dm && console.log("Entering - downloadNextBottom(): - dressNoBottom=" + dressNoBottom + ", bottomDownloadSeq=" + bottomDownloadSeq);
    props.dm && console.log("DNB - DressGlbSingleton.getCancelSeqNo(2)=" + DressGlbSingleton.getCancelSeqNo(2));
    if (bottomDownloadSeq < DressGlbSingleton.getCancelSeqNo(2)) {
      props.dm && console.log("Stopping current download sequence of downloadNextBottom, bottomDownloadSeq=" + bottomDownloadSeq)
      return;
    }
    let nextIndex = dressNoBottom;
    if (checkAndDownload(bottomCollectionName, bottomFileUrls, nextIndex, 2)) return;

    nextIndex = (dressNoBottom + 1) % listBottomPHs.current.length;
    if (checkAndDownload(bottomCollectionName, bottomFileUrls, nextIndex, 2)) return;

    nextIndex = dressNoBottom - 1;
    if (nextIndex < 0) nextIndex = nextIndex + listBottomPHs.current.length;
    if (checkAndDownload(bottomCollectionName, bottomFileUrls, nextIndex, 2)) return;

    for (let i = dressNoBottom + 2; i <= dressNoBottom + props.storeConfig.loadNextNum; i++) {
      nextIndex = i % listBottomPHs.current.length;
      if (checkAndDownload(bottomCollectionName, bottomFileUrls, nextIndex, 2)) return;
    }

    for (let i = dressNoBottom - 2; i >= dressNoBottom - props.storeConfig.loadPrevNum; i--) {
      nextIndex = i % listBottomPHs.current.length;
      if (nextIndex < 0) nextIndex += listBottomPHs.current.length;
      if (checkAndDownload(bottomCollectionName, bottomFileUrls, nextIndex, 2)) return;
    }
  }

  function startDownloadSequenceTop() {
    props.dm && console.log("Entering - startDownloadSequenceTop, topDownloadSeq=" + topDownloadSeq, dressNoTop);
    props.dm && console.log("SDST - DressGlbSingleton.getCancelSeqNo(1)=" + DressGlbSingleton.getCancelSeqNo(1));
    if (!DressGlbSingleton.isDownloadInProgress(1)) {
      props.dm && console.log("startDownloadSequenceTop - new thread has started, clearing the interval timerIdsTop=" +
        timerIdsTop.current + ", my topDownloadSeq=" + topDownloadSeq);
      let maxIdx = -1;
      if (timerIdsTop.current.length === 1) {
        maxIdx = 1;
      }
      else if (timerIdsTop.current.length > 1) {
        maxIdx = timerIdsTop.current.length - 1;
      }
      for (let i = 0; i < maxIdx; i++) {
        props.dm && console.log("Clearing Timer - Top " + timerIdsTop.current[i] + ", my topDownloadSeq=" + topDownloadSeq);
        clearInterval(timerIdsTop.current[i]);
      }
      timerIdsTop.current.splice(0, maxIdx);
      props.dm && console.log("startDownloadSequenceTop - after clearing - timerIdsTop=" + timerIdsTop.current);
      downloadNextTop();
    }
  }

  function startDownloadSequenceBottom() {
    props.dm && console.log("Entering - startDownloadSequenceBottom, bottomDownloadSeq=" + bottomDownloadSeq);
    props.dm && console.log("SDSB - DressGlbSingleton.getCancelSeqNo(2)=" + DressGlbSingleton.getCancelSeqNo(2));

    if (!DressGlbSingleton.isDownloadInProgress(2)) {
      props.dm && console.log("startDownloadSequenceBottom - new thread has started, clearing the interval timerIdsBottom="
        + timerIdsBottom.current + ", my bottomDownloadSeq=" + bottomDownloadSeq);
      let maxIdx = -1;
      if (timerIdsBottom.current.length === 1) {
        maxIdx = 1;
      }
      else if (timerIdsBottom.current.length > 1) {
        maxIdx = timerIdsBottom.current.length - 1;
      }
      for (let i = 0; i < maxIdx; i++) {
        props.dm && console.log("Clearing Timer - Bottom " + timerIdsBottom.current[i] + ", my bottomDownloadSeq=" + bottomDownloadSeq);
        clearInterval(timerIdsBottom.current[i]);
      }
      timerIdsBottom.current.splice(0, maxIdx);
      props.dm && console.log("startDownloadSequenceBottom - after clearing - timerIdsBottom=" + timerIdsBottom.current);
      listBottomPHs.current && downloadNextBottom();
    }
  }

  function fetchProdHandles() {
    //Fetch store and product info 
    return new Promise(function (resolve, reject) {
      const url = BASE_URL + "/getProdHandles";
      const myData = {
        store_name: storeName,
      };
      requestHandlerFunction(url, "getProdHandles", "get", "json", myData)
        .then(function (resp) {
          props.dm && console.log(resp[0].data.data);
          resolve(resp[0].data.data);
        })
        .catch(function (error) {
          reject(error);
        });
    });
  }
  function hideLoadingGif() {
    restartTimer("hideLoadingGif");
    setShowLoadingGif(false)
  }

  function startRefresh() {
    let refreshStartedSess = sessionStorage.getItem('refreshStartedSess');
    if (refreshStartedSess === undefined || refreshStartedSess === null || refreshStartedSess !== "1") {
      UXLog(storeName, 'useEffect: CRASH or REFRESH', "", "", "");
      sessionStorage.setItem('refreshStartedSess', "1");
    }
  }

  function checkColorsInSession() {
    let topColor = sessionStorage.getItem('topColor');
    let bottomColor = sessionStorage.getItem('bottomColor');
    if (topColor !== null || bottomColor !== null) {
      startRefresh();
    }
  }

  function getCurrTopNo() {
    let currTopNo = sessionStorage.getItem('dressNoTopSess');
    if (currTopNo !== null) {
      currTopNo = parseInt(currTopNo);
      NextdressTop(currTopNo);
      startRefresh();
    }
    else {
      currTopNo = dressNoTop;
    }
    props.dm && console.log("getCurrTopNo(): ", currTopNo);
    return currTopNo;
  }

  function getCurrBottomNo() {
    let currBottomNo = sessionStorage.getItem('dressNoBottomSess');
    if (currBottomNo !== null) {
      currBottomNo = parseInt(currBottomNo);
      NextdressBottom(currBottomNo);
      startRefresh();
    }
    else {
      currBottomNo = dressNoBottom;
    }
    props.dm && console.log("getCurrBottomNo(): ", currBottomNo);
    return currBottomNo;
  }

  useEffect(() => {
    //Download the first top and bottom garments as soon as the component loads
    if (effectRan.current === false) {
      props.dm && console.log("props.storeConfig: ", props.storeConfig);
      fetchProdHandles().then(function (resp) {
        listTopPHs.current = resp['SBV-3D-A-Upper'];
        listBottomPHs.current = resp['SBV-3D-B-Lower'];
        const curTopNo = getCurrTopNo();
        const curBottomNo = getCurrBottomNo();
        checkColorsInSession();
        if (listTopPHs.current.length) {
          const newNextNo = (curTopNo !== listTopPHs.current.length - 1) ? curTopNo + 1 : 0;
          const newPrevNo = (curTopNo !== 0) ? curTopNo - 1 : listTopPHs.current.length - 1;
          const fetchImages = async () => {
            const prevImg = await getImgFromProductHandle((colorVarTop) ? colorVarTop : "Default", listTopPHs.current[newPrevNo]);
            const nextImg = await getImgFromProductHandle((colorVarTop) ? colorVarTop : "Default", listTopPHs.current[newNextNo]);
            setTopPrevImgUrl(prevImg);
            setTopNextImgUrl(nextImg);
          };
          fetchImages();
        }
        if (listBottomPHs.current && listBottomPHs.current.length) {
          let newNextNo = (curBottomNo !== listBottomPHs.current.length - 1) ? curBottomNo + 1 : 0;
          let newPrevNo = (curBottomNo !== 0) ? curBottomNo - 1 : listBottomPHs.current.length - 1;
          const fetchImages = async () => {
            const prevImg = await getImgFromProductHandle((colorVarBottom) ? colorVarBottom : "Default", listBottomPHs.current[newPrevNo]);
            const nextImg = await getImgFromProductHandle((colorVarBottom) ? colorVarBottom : "Default", listBottomPHs.current[newNextNo]);
            setBottomPrevImgUrl(prevImg);
            setBottomNextImgUrl(nextImg);
          };
          fetchImages();
        }
        if (!IS_LOCAL) props.setUpperPHFunction(listTopPHs.current[curTopNo]); // This helps change the product in cart box.
        if (!IS_LOCAL) listBottomPHs.current && props.setLowerPHFunction(listBottomPHs.current[curBottomNo]);
        props.dm && console.log("List of Top PHs: ", listTopPHs.current);
        props.dm && console.log("List of Bottom PHs: ", listBottomPHs.current);
        setTopDownloadSeq(prevTopDownloadSeq => prevTopDownloadSeq + 1);
        if (curTopNo === 0) startDownloadSequenceTop();
        setBottomDownloadSeq(prevBottomDownloadSeq => prevBottomDownloadSeq + 1);
        if (curBottomNo === 0) startDownloadSequenceBottom();
      });
      setTimeout(() => { hideLoadingGif() }, 5000);
      return () => {
        effectRan.current = true;
      };
    }
  }, []);


  function Env() {
    return <></>;
    // return <Environment files={BASE_URL + '/static/media/' + envObj[props.storeConfig.defaultEnvName].fileName} />;
  }


  const addDirectionalLight = (x, y, z, i, color, target = [0, 0, 0]) => {
    const light = new DirectionalLight(color, i);
    light.position.set(x, y, z);
    light.target.position.set(target[0], target[1], target[2]);
    const helper = new DirectionalLightHelper(light, 1, 'black');
    helper.position.set(x, y, z);
    return { light: light, helper: helper };
  };
  function addSpotLight(x, y, z, i, color, target = [0, 0, 0], angle = Math.PI / 3) {
    const light = new SpotLight(color, i);
    light.position.set(x, y, z);
    light.angle = angle;
    light.target.position.set(target[0], target[1], target[2]);
    const helper = new SpotLightHelper(light, 'red');
    return { light: light, helper: helper };
  }

  function Lighting() {
    const { light: lightKey, helper: helperKey } = addDirectionalLight(3, 6.5, 8.5, 1, 'white', [0, -1, 0]);
    const { light: lightFill, helper: helperLightFill } = addDirectionalLight(-4, 1.75, 5.5, 0.2, 'white');
    const { light: lightBackLeft, helper: helperBackLeft } = addDirectionalLight(-4, 3.3, -5.5, 0.3, 'white', [0, 1.6, 0]);
    const { light: lightFrontRight, helper: helperFrontRight } = addSpotLight(2, -1.5, 2.5, 0.2, 'white');
    const { light: lightFace, helper: helperFace } = addDirectionalLight(0.5, 0.5, 0.5, 0.1, 'white', [0, 1.2, 0]);
    lightKey.castShadow = true;
    lightFrontRight.castShadow = true;
    lightKey.shadow.bias = 0.002;
    lightKey.shadow.radius = 1;
    return (
      <>
        <primitive object={lightKey} />
        <primitive object={lightFill} />
        <primitive object={lightBackLeft} />
        <primitive object={lightFrontRight} />
        <primitive object={lightFace} />

        {/* <primitive object={helperKey} /> */}
        {/* <primitive object={helperLightFill} /> */}
        {/* <primitive object={helperBackLeft} /> */}
        {/* <primitive object={helperFrontRight} /> */}
        {/* <primitive object={helperFace} /> */}
      </>
    )
  }

  function clearExtra3DModels(fileUrls, phList, topOrBottom) {
    // Function to unload and delete the GLBs that are not required.
    const currentProductHandle = topOrBottom === 1 ? listTopPHs.current[dressNoTop] : listBottomPHs.current[dressNoBottom];
    const phToRetain = new Set();
    phToRetain.add(currentProductHandle);

    const currentPoductHandleIndex = phList.current.indexOf(currentProductHandle);
    for (let i = currentPoductHandleIndex + 1; i <= currentPoductHandleIndex + props.storeConfig.loadNextNum; i++) {
      let index = i % phList.current.length;
      phToRetain.add(phList.current[index]);
    }
    for (let i = currentPoductHandleIndex - 1; i >= currentPoductHandleIndex - props.storeConfig.loadPrevNum; i--) {
      let index = i % phList.current.length;
      if (index < 0) index += phList.current.length;
      phToRetain.add(phList.current[index]);
    }
    props.dm && console.log(phToRetain);
    phList.current.map((garment, idx) => {
      if (!phToRetain.has(garment)) {
        useGLTF.clear(fileUrls.current[garment]);
        fileUrls.current[garment] = null;
        DressGlbSingleton.deleteFile(garment, topOrBottom);
        disposeCurrentItem(garment, topOrBottom);
        props.dm && console.log('Cleared: ph=' + garment + ", idx=" + idx);
      }
    })
  }

  useEffect(() => {
    props.dm && console.log("useEffect [downloadStatusTop]: dressNoTop=" + dressNoTop + ", downloadStatusTop="
      + downloadStatusTop + ", firstTime2t.current=" + firstTime2t.current);
    if (firstTime2t.current) { firstTime2t.current = false; return }
    if (downloadStatusTop === 2) {
      const currentPoductHandle = listTopPHs.current[dressNoTop];
      currTopPhRef.current = currentPoductHandle;
      const url = topFileUrls.current[currentPoductHandle];
      props.dm && console.log("useEffect [downloadStatusTop]: , url=" + url + ", currTopUrl=" + currTopUrl);
      if (url && url !== null && currTopUrl !== url) {
        setCurrTopUrl(url);
      }
      clearExtra3DModels(topFileUrls, listTopPHs, 1);
    }
  }, [downloadStatusTop]);

  useEffect(() => {
    props.dm && console.log("useEffect [downloadStatusBottom]: dressNoBottom=" + dressNoBottom + ", downloadStatusBottom="
      + downloadStatusBottom + ", firstTime2b.current=" + firstTime2b.current);
    if (firstTime2b.current) { firstTime2b.current = false; return }
    if (downloadStatusBottom === 2) {
      const currentPoductHandle = listBottomPHs.current[dressNoBottom];
      currBottomPhRef.current = currentPoductHandle;
      const url = bottomFileUrls.current[currentPoductHandle];
      if (url && url !== null && currBottomUrl !== url) {
        setCurrBottomUrl(url);
      }
      clearExtra3DModels(bottomFileUrls, listBottomPHs, 2);
    }
  }, [downloadStatusBottom]);

  const getImgFromProductHandle = async (colorVariant, ph) => {
    const url = `${BASE_URL}/getProductImgUrl?store_name=${encodeURIComponent(storeName)}&product_handle=${encodeURIComponent(ph)}&color_variant=${encodeURIComponent(colorVariant)}`;
    try {
      const response = await fetch(url);
      if (!response.ok) {
        props.dm && console.log("getImgFromProductHandle: Not able to fetch image, Request failed with status:", response.status);
        return null;
      }
      const data = await response.json();
      // let img_url = (!IS_LOCAL) ? data.product.image.src : data.data;
      let img_url = data.data;
      props.dm && console.log('getImgFromProductHandle: Response data:', data);
      props.dm && console.log('getImgFromProductHandle: Response img url:', img_url);
      return img_url;
    } catch (error) {
      props.dm && console.error('getImgFromProductHandle: Error:', error);
      return null;
    }

    // const url = (!IS_LOCAL) ? `https://${storeName}/products/${ph}.js` : `https://d1.engagevida.com/getProdImgUrl?store_name=${storeName}&collection_name=${collectionName}&product_handle=${ph}`;
    // try {
    //   const response = await fetch(url);
    //   if (!response.ok) {
    //     props.dm && console.log("getImgFromProductHandle: Not able to fetch image, Request failed with status:",response.status);
    //     return null;
    //   }
    //   const data = await response.json();
    //   // let img_url = (!IS_LOCAL) ? data.product.image.src : data.data;
    //   let img_url = (!IS_LOCAL) ? data.featured_image : data.data;
    //   if(!IS_LOCAL){
    //     let colorVariant = (collectionName === "SBV-3D-A-Upper") ? colorVarTop: colorVarBottom;
    //     for(const variant of data.variants)
    //     {
    //         const hasMatchingOption = variant.options.includes(colorVariant);
    //         if (variant.featured_image !== null && hasMatchingOption) {
    //           img_url = variant.featured_image.src;
    //           break;
    //         }
    //     }
    //   }
    //   props.dm && console.log('getImgFromProductHandle: Response data:', data);
    //   props.dm && console.log('getImgFromProductHandle: Response img url:', img_url);
    //   return img_url;
    // } catch (error) {
    //   props.dm && console.error('getImgFromProductHandle: Error:', error);
    //   return null;
    // }
  };

  useEffect(() => {

    if (listTopPHs.current.length) {
      const newNextNo = (dressNoTop !== listTopPHs.current.length - 1) ? dressNoTop + 1 : 0;
      const newPrevNo = (dressNoTop !== 0) ? dressNoTop - 1 : listTopPHs.current.length - 1;

      const fetchImages = async () => {
        const prevImg = await getImgFromProductHandle((colorVarTop) ? colorVarTop : "Default", listTopPHs.current[newPrevNo]);
        const nextImg = await getImgFromProductHandle((colorVarTop) ? colorVarTop : "Default", listTopPHs.current[newNextNo]);
        setTopPrevImgUrl(prevImg);
        setTopNextImgUrl(nextImg);
      };
      fetchImages();
    }
    if (firstTime1t.current) { firstTime1t.current = false; return }
    props.dm && console.log("useEffect [dressNoTop]: dressNoTop = " + dressNoTop);
    DressGlbSingleton.setCancelSeqNo(topDownloadSeq, 1);
    setTopDownloadSeq(prevTopDownloadSeq => prevTopDownloadSeq + 1);
    const newTimer = setInterval(startDownloadSequenceTop, 1000);
    timerIdsTop.current.push(newTimer);
    props.dm && console.log("starting setInterval for startDownloadSequenceTop  topDownloadSeq=" + topDownloadSeq + ", timerId=" + newTimer);
  }, [dressNoTop]);

  useEffect(() => {
    if (listBottomPHs.current && listBottomPHs.current.length) {
      let newNextNo = (dressNoBottom !== listBottomPHs.current.length - 1) ? dressNoBottom + 1 : 0;
      let newPrevNo = (dressNoBottom !== 0) ? dressNoBottom - 1 : listBottomPHs.current.length - 1;

      const fetchImages = async () => {
        const prevImg = await getImgFromProductHandle((colorVarBottom) ? colorVarBottom : "Default", listBottomPHs.current[newPrevNo]);
        const nextImg = await getImgFromProductHandle((colorVarBottom) ? colorVarBottom : "Default", listBottomPHs.current[newNextNo]);
        setBottomPrevImgUrl(prevImg);
        setBottomNextImgUrl(nextImg);
      };
      fetchImages();
    }
    if (firstTime1b.current) { firstTime1b.current = false; return }
    props.dm && console.log("useEffect [dressNoBottom]: dressNoBottom = " + dressNoBottom);
    DressGlbSingleton.setCancelSeqNo(bottomDownloadSeq, 2);
    setBottomDownloadSeq(prevBottomDownloadSeq => prevBottomDownloadSeq + 1);
    const newTimer = setInterval(startDownloadSequenceBottom, 1000);
    timerIdsBottom.current.push(newTimer);
    props.dm && console.log("starting setInterval for startDownloadSequenceBottom  bottomDownloadSeq=" + bottomDownloadSeq + ", timerId=" + newTimer);
  }, [dressNoBottom]);

  useEffect(() => {

    if (listTopPHs.current.length) {
      const newNextNo = (dressNoTop !== listTopPHs.current.length - 1) ? dressNoTop + 1 : 0;
      const newPrevNo = (dressNoTop !== 0) ? dressNoTop - 1 : listTopPHs.current.length - 1;

      const fetchImages = async () => {
        const prevImg = await getImgFromProductHandle((colorVarTop) ? colorVarTop : "Default", listTopPHs.current[newPrevNo]);
        const nextImg = await getImgFromProductHandle((colorVarTop) ? colorVarTop : "Default", listTopPHs.current[newNextNo]);
        setTopPrevImgUrl(prevImg);
        setTopNextImgUrl(nextImg);
      };
      fetchImages();
    }
  }, [colorVarTop]);

  useEffect(() => {
    if (listBottomPHs.current && listBottomPHs.current.length) {
      let newNextNo = (dressNoBottom !== listBottomPHs.current.length - 1) ? dressNoBottom + 1 : 0;
      let newPrevNo = (dressNoBottom !== 0) ? dressNoBottom - 1 : listBottomPHs.current.length - 1;

      const fetchImages = async () => {
        const prevImg = await getImgFromProductHandle((colorVarBottom) ? colorVarBottom : "Default", listBottomPHs.current[newPrevNo]);
        const nextImg = await getImgFromProductHandle((colorVarBottom) ? colorVarBottom : "Default", listBottomPHs.current[newNextNo]);
        setBottomPrevImgUrl(prevImg);
        setBottomNextImgUrl(nextImg);
      };
      fetchImages();
    }
  }, [colorVarBottom]);

  useEffect(() => {
    if (!changeColorAssigned.current) {
      window.sbv_mnm = sbv_mnm;
      changeColorAssigned.current = true;
    }
  }, []);


  function GarmentSpawn(props) {
    const currentProductUrl = props.currentProductURL;
    props.dm && console.log("Entering - GarmentSpawn(): currentProductUrl=" + currentProductUrl);
    let finalUrl = null;
    if (currentProductUrl !== null && currentProductUrl !== undefined) {
      props.dm && console.log("GarmentSpawn(): fileUrl if: " + currentProductUrl);
      finalUrl = currentProductUrl;

      let loadingStartTime = props.topOrBottom === 1 ? loadingStartTimeTop : loadingStartTimeBottom;
      if (loadingStartTime !== null) {
        const endTime = new Date();
        let diff = (endTime - loadingStartTime) / 1000;
        const ux_value = props.topOrBottom === 1 ? listTopPHs.current[dressNoTop] : listBottomPHs.current[dressNoBottom];
        const misc_info = props.topOrBottom === 1 ? "top" : "bottom";
        UXLog(storeName, 'MainDressViewer:GarmentSpawn LoadingTime(sec): ', diff, ux_value, misc_info);
        props.topOrBottom === 1 ? setLoadingStartTimeTop(null) : setLoadingStartTimeBottom(null);
      }
    } else {
      props.dm && console.log("GarmentSpawn(): fileUrl else: " + currentProductUrl);
      finalUrl = (props.topOrBottom === 1) ? loadingGlbTOP : loadingGlbBOTTOM;
      let loadingStartTime = props.topOrBottom === 1 ? loadingStartTimeTop : loadingStartTimeBottom;
      if (loadingStartTime === null) {
        const startTime = new Date();
        props.topOrBottom === 1 ? setLoadingStartTimeTop(startTime) : setLoadingStartTimeBottom(startTime);
      }
    }
    const gltf = useGLTF(finalUrl);
    props.currGltf.current = gltf;

    if (props.currGltf.current && props.currPh.current && (finalUrl !== loadingGlbTOP && finalUrl !== loadingGlbBOTTOM)) {
      props.topOrBottom === 1 ? prevGltfTop.current = props.currGltf.current : prevGltfBottom.current = props.currGltf.current;
      if (props.topOrBottom === 1) {
        phGltfTopsRef.current[props.currPh.current] = props.currGltf.current
      }
      else {
        phGltfBottomsRef.current[props.currPh.current] = props.currGltf.current
      }
    }

    const { nodes } = gltf;
    adjustMaterialProperties(nodes);
    gltf.scene.traverse((child) => {
      if (child.isMesh) {
        child.castShadow = true;
      }
    })
    // gltf.scene.children = gltf.scene.children.filter((child) => !child.isLight);
    if (currentProductUrl !== null && currentProductUrl !== undefined) changeColorAgain(props.topOrBottom);
    DressGlbSingleton.getWholeFile();
    const garment_ref = useRef()
    let isClockwise = false;
    useFrame(() => {
      if (rotationState.current === 3) {
        return false
      }
      else if (rotationState.current === 2) {
        if (garment_ref.current) {
          if (isClockwise) {
            garment_ref.current.rotation.y += time_step
            if (garment_ref.current.rotation.y > rotation_angle) {
              isClockwise = false;
            }
          }
          if (!isClockwise) {
            garment_ref.current.rotation.y -= time_step
            if (garment_ref.current.rotation.y < -rotation_angle) {
              isClockwise = true;
            }
          }
        }
      }
      else if (rotationState.current === 1) {
        return true;
      }

    })
    return (
      <mesh ref={garment_ref} position={[0, -3.4, 0]} scale={2.8} castShadow>
        <primitive object={gltf.scene} />
      </mesh>
    );
  }

  const adjustMaterialProperties = (nodes) => {
    Object.values(nodes).forEach((node) => {
      // console.log('===========================>>>Name:', node.name);
      if (node.material) {
        // console.log('Material:', node.material);
        // console.log('Metallicness:', node.material.metalness);
        // console.log('Roughness:', node.material.roughness);
        // console.log('clearcoat:', node.material.clearcoat);
        // console.log('clearcoatRoughness:', node.material.clearcoatRoughness);
        // console.log('Normal Map:', node.material.normalMap);
        // console.log('Opacity:', node.material.opacity);
        // console.log('Opacity - Side:', node.material.side);
        // console.log('Opacity - isDoubleSide:', node.material.side === DoubleSide);
        // console.log('Transparent:', node.material.transparent);
        // console.log('gammaFactor:', node.material.gammaFactor);
        // console.log('Color:', node.material.color);
        // console.log('Vertex Shader:', node.material.vertexShader);
        // console.log('Fragment Shader:', node.material.fragmentShader);
        if (props.storeConfig.isHDRLighting && node.isMesh) {
          node.material.envMapIntensity = props.storeConfig.defaultEnvExposure;
        }
        node.material.side = DoubleSide;
        if (node.name !== null && node.name !== undefined && node.name.startsWith('Trim') && node.isMesh) {
          node.material.metalness = 0.7;
          node.material.roughness = 0.20;
          node.material.clearcoat = 1;
          // console.log('New Metallicness:', node.material.metalness);
          // console.log('New roughness:', node.material.roughness);
          // console.log('New clearcoat:', node.material.clearcoat);
        }
      }
    });
  };

  function AvatarSpawn() {
    let finalUrl = null;
    if (avatarUrl !== null && avatarUrl !== undefined) {
      props.dm && console.log("avatarUrl if: " + avatarUrl);
      finalUrl = avatarUrl;
    } else {
      props.dm && console.log("fileUrl else: " + avatarUrl);
      finalUrl = loadingGlbTOP;
    }
    const gltf = useGLTF(finalUrl);
    gltf.scene.traverse((child) => {
      if (child.isMesh) {
        child.castShadow = true;
      }
    })
    const avatar_ref = useRef()
    let isClockwise = false;
    useFrame(() => {
      if (rotationState.current === 3) {
        return false
      }
      else if (rotationState.current === 2) {
        if (avatar_ref.current) {
          if (isClockwise) {
            avatar_ref.current.rotation.y += time_step
            if (avatar_ref.current.rotation.y > rotation_angle) {
              isClockwise = false;
            }
          }
          if (!isClockwise) {
            avatar_ref.current.rotation.y -= time_step
            if (avatar_ref.current.rotation.y < 0.001 && avatar_ref.current.rotation.y > 0) {
              // setRotation_count(prev => prev += 1)
              // console.log('rotation_count', rotation_count);
              // if(rotation_count > 0) {
              //   setRotationState(3)
              // }
              props.dm && console.log("AvatarSpawn(): setRotationState - 3")
              rotationState.current = 3;
            }
            if (avatar_ref.current.rotation.y < -rotation_angle) {
              isClockwise = true;
            }
          }
        }
      }
      else if (rotationState.current === 1) {
        return true;
      }


    })

    const { gl, scene, camera } = useThree()
    meshGl.current = gl;
    meshCamera.current = camera;
    meshScene.current = scene;

    return (
      <mesh ref={avatar_ref} position={[0, -3.4, 0]} scale={2.8} castShadow>
        <primitive object={gltf.scene} />
      </mesh>
    );
  }

  const twentySecFunc = () => {
    props.dm && console.log("Entering twentySecFunc() - isMoved", isMoved.current);
    if (firstTimeBlinking.current && !isMoved.current && rotationState.current === 1) {
      isBlinking.current = false;
      setIsBlinkingState(false);
      firstTimeBlinking.current = false;
      rotationState.current = 2;
      props.dm && console.log("twentySecFunc(): Start - Rotation");
    }
    else if (!isBlinking.current) {
      props.dm && console.log("twentySecFunc(): Start - Blinking");
      firstTimeBlinking.current = true;
      isBlinking.current = true;
      setIsBlinkingState(true);
    }
    restartTimer("twentySecFunc", false);
  };

  const restartTimer = (place_int, toReset = true) => {
    props.dm && console.log("Entering restartTimer(): ", place_int)
    if (toReset) {
      isBlinking.current = false;
      setIsBlinkingState(false);
      props.dm && console.log("restartTimer(): Marking - Blinking - False");
    }
    if (blinkingTimerId.current.length > 0) {
      for (let i = 0; i < blinkingTimerId.current.length; i++) {
        props.dm && console.log("Clearing restartTimer(): ", blinkingTimerId.current[i])
        clearTimeout(blinkingTimerId.current[i]);
      }
      blinkingTimerId.current = []
    }
    const timerId = setTimeout(() => {
      twentySecFunc();
    }, 15000);
    blinkingTimerId.current.push(timerId);
    props.dm && console.log("Exiting restartTimer(): timerId = ", timerId)
  };

  const handlePressed = (id, changeDress) => {
    setIsPressed((prevState) => ({
      ...prevState,
      [id]: true,
    }));
    changeDress();
    setTimeout(() => {
      handlePressRelease(id);
    }, 250);

    restartTimer("handlePressed");
    // clearTimeout(blinkingTimerId);
    // setIsBlinking(false);
    // const timerId = setTimeout(() => {
    //   setIsBlinking(true);
    // }, 20000);
    // setBlinkingTimerId(timerId);
  };

  const handlePressRelease = (id) => {
    setIsPressed((prevState) => ({
      ...prevState,
      [id]: false,
    }));
  };

  const NavButtons = (props) => {
    let srcImg = (props.type === 'prev') ? ((props.topOrBottom == "Top") ? topPrevImgUrl : bottomPrevImgUrl) : ((props.topOrBottom == "Top") ? topNextImgUrl : bottomNextImgUrl);
    return (
      <button onClick={() => { handlePressed(`${props.type}${props.topOrBottom}`, props.clickEvent); }} className={`button ${isBlinkingState ? 'blinking' : ''} ${isPressed[`${props.type}${props.topOrBottom}`] ? 'buttonPressed' : ''} button${props.topOrBottom} btn${props.type}`}
        id={`${props.type}Button` + props.topOrBottom}>
        {/* <img src={(props.type === 'prev') ? left_button : right_button} className="buttonImage" alt="btn" /> */}
        <img src={srcImg == null ? ((props.type === 'prev') ? left_button : right_button) : srcImg} className="buttonImage" alt="btn" />
      </button>
      // <button onClick={props.clickEvent} className={`button ${isPressed[`${props.type}${props.topOrBottom}`] ? 'buttonPressed' : ''} button${props.topOrBottom} btn${props.type}`}
      //   onTouchStart={() => handlePressed(`${props.type}${props.topOrBottom}`, props.clickEvent)} 
      //   onTouchEnd={() => handlePressRelease(`${props.type}${props.topOrBottom}`)}
      //   onMouseDown={() => handlePressed(`${props.type}${props.topOrBottom}`, props.clickEvent)} 
      //   onMouseUp={() => handlePressRelease(`${props.type}${props.topOrBottom}`)}
      //   onTouchCancel={() => handlePressRelease(`${props.type}${props.topOrBottom}`)} 
      //   id={`${props.type}Button` + props.topOrBottom}>
      //   <img src={(props.type === 'prev') ? left_button : right_button} className="buttonImage" alt="btn" />
      // </button>
    );
  };

  const BrandTag = () => {
    return (
      <div className="brandTag">
        <p>Powered by @stylebyvida</p>
      </div>
    );
  };

  function AvatarToggle() {
    return (
      <div className="avatar-toggle-reset-controls">
        <button className="avatar-toggle" onClick={() => avatarToggle()}
          disabled={avatarUrl === null ? true : false}
          style={avatarUrl === null ? { opacity: '0.3' } : { opacity: '1' }}>
          {toggleAvatar && (
            <>
              <img src={avatarOnToggleSvg} style={{ visibility: 'visible' }} className="avatar-toggle-image" />
              <img src={avatarOffToggleSvg} style={{ visibility: 'hidden' }} className="avatar-toggle-image" />
            </>
          )}
          {!toggleAvatar && (
            <>
              <img src={avatarOnToggleSvg} style={{ visibility: 'hidden' }} className="avatar-toggle-image" />
              <img src={avatarOffToggleSvg} style={{ visibility: 'visible' }} className="avatar-toggle-image" />
            </>
          )}
        </button>
        <button onClick={resetOrbitControls} className="reset-button"><img src={reset_button} style={{ height: '30px' }} /></button>
      </div>
    );
  }
  function resetOrbitControls() {
    orbitControlsRef.current.reset();
    restartTimer("resetOrbitControls");
  }

  function Loader1() {
    //Component to render the loading progress of 3d models
    const { active, progress, errors, item, loaded, total } = useProgress();
    if (!showLoadingGif) {
      return (
        <Html center style={{ color: "black" }}>
          {Math.round(progress, 2)} % loaded
        </Html>
      );
    }
  }

  function InitialLoadingGif() {
    if (showLoadingGif) {
      return (
        <div className="init-load-div">
          <img src={initial_loading_gif} className="initial-loading-gif"></img>
        </div>
      )
    }
  }
  function HelperToggle() {
    return (
      <div className="helper-toggle">
        <img src={helper_icon} style={{ height: '22px' }} onClick={toggleHelper} />
      </div>
    )
  }
  function DownloadCanvas() {
    return (
      <div className="download-canvas"  >
        <img src={download_icon_svg} style={{ height: '22px' }} onClick={captureCanvas} />
      </div>
    )
  }


  function toggleHelper() {
    if (showHelper) setShowHelper(false);
    else setShowHelper(true);
    restartTimer("toggleHelper");
  }

  function HelperComponent() {
    let helperShown = localStorage.getItem("helperShown");
    if (helperShown === null || helperShown === undefined || showHelper) {
      localStorage.setItem("helperShown", true);
      return (
        <div className="helper-component" id="helper-component" onClick={toggleHelper}>
          <div className="overlay"></div>
          <img src={helper_nav_button} className="helper-nav-button" ></img>
          <img src={helper_toggleAvatar} className="helper-toggle-avatar"></img>
          <img src={helper_zoom} className="helper-zoom"></img>
          <img src={helper_reset} className="helper-reset"></img>
          <img src={helper_rotate} className="helper-rotate"></img>
          <img src={helper_proceed} className="helper-close"></img>
        </div>
      )
    } else return;

  }

  function Plane(props) {
    return <mesh {...props} receiveShadow>
      <planeGeometry args={[20, 20]} />
      <meshStandardMaterial color="white">
        {/* <GradientTexture
                stops={[0, 0.4, 1]} // As many stops as you want
                colors={['purple', 'hotpink', 'black']} // Colors need to match the number of stops
                size={1024} // Size is optional, default = 1024
                /> */}
      </meshStandardMaterial>
    </mesh>
  }

  const incrementIntCount = () => {
    let INT_COUNT = localStorage.getItem(`SBV_INT_COUNT_${storeName}`);
    if (INT_COUNT != null && INT_COUNT !== undefined && localStorage.getItem(`SBV_RATED${storeName}`) !== 'true') {
      let count = parseInt(INT_COUNT);
      if ((count + 1) === 30 || ((count + 1) % 100) === 0) {
        setShowFeedback(true);
      }
      else {
        setShowFeedback(false);
      }
    }
    INT_COUNT ? (localStorage.setItem(`SBV_INT_COUNT_${storeName}`, parseInt(INT_COUNT) + 1)) : (localStorage.setItem(`SBV_INT_COUNT_${storeName}`, 1));
  };

  const handleMouseDown = () => {
    setIsDragging(true);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = () => {
    if (isDragging) {
      props.dm && console.log('handleMouseMove() moved----------------------------');
      isMoved.current = true;
      // TODO UX log
      UXLog(storeName, 'MainDressViewer:Canvas', 'modelMoved');
    }
  };

  const handleTouchMove = (e) => {
    e.preventDefault();
    props.dm && console.log('handleTouchMove() moved----------------------------');
    isMoved.current = true;
    // TODO UX log
    UXLog(storeName, 'MainDressViewer:Canvas', 'modelMoved');
  }

  const captureCanvas = () => {
    const gl = meshGl.current;
    const scene = meshScene.current;
    const camera = meshCamera.current;
    if (gl && scene && camera) {
      gl.render(scene, camera)
      const dataUrl = gl.domElement.toDataURL('image/png')
      const link = document.createElement('a');
      link.href = dataUrl;
      link.download = 'canvas_image.png'; // Set desired filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      console.log(dataUrl)
    }
  }

  return (
    <>
      {showFeedback && <div className="overlay2"></div>}
      {props.planExpired && <div className="overlay2">
        <div style={{ position: "absolute", color: "white", left: "0", right: "0", top: "0", bottom: "0", display: "flex", justifyContent: "center", alignItems: "center" }}>
          YOUR MERCHANT PLAN HAS BEEN EXPIRED!
        </div>
      </div>}
      <div id="MainDressViewer" className="MDV">
        {showFeedback && <Feedback storeName={storeName} setShowFeedback={setShowFeedback} />}
        {!showLoadingGif && <HelperComponent />}
        <InitialLoadingGif />
        {!showLoadingGif && <HelperToggle />}
        {!showLoadingGif && <DownloadCanvas />}
        <Canvas
          shadows
          // camera={{ fov: 30, position: [0, 1, 6], far: 50 }} 
          camera={{ fov: 30, position: [0, 0, 13], far: 50 }}
          gl={{ toneMapping: 0 }}
          onMouseDown={() => !isMoved.current ? handleMouseDown() : null}
          onMouseUp={() => !isMoved.current ? handleMouseUp() : null}
          onMouseMove={() => !isMoved.current ? handleMouseMove() : null}
          onTouchMove={(e) => !isMoved.current ? handleTouchMove(e) : null}
        >
          <Suspense fallback={<Loader1 />}>
            {props.storeConfig.isHDRLighting && < Env />}

            <Plane position={[0, 0, -4]} isGround={false} />
            <Plane position={[0, -3.35, 0]} rotation={[-Math.PI / 2, 0, 0]} isGround={true} />
            <PresentationControls
              enabled={true} // the controls can be disabled by setting this to false
              global={true} // Spin globally or by dragging the model
              cursor={true} // Whether to toggle cursor style on drag
              snap={false} // Snap-back to center (can also be a spring config)
              speed={1} // Speed factor
              zoom={1} // Zoom factor when half the polar-max is reached
              rotation={[0, 0, 0]} // Default rotation
              polar={[0, 0]} // Vertical limits
              azimuth={[-Infinity, Infinity]} // Horizontal limits
              config={{ mass: 0, tension: 0, friction: 0 }} // Spring config
            >
              <GarmentSpawn currentProductURL={currTopUrl} currGltf={currGltfTop} topOrBottom={1} currPh={currTopPhRef} />
              {listBottomPHs.current.length > 0 && <GarmentSpawn currentProductURL={currBottomUrl} currGltf={currGltfBottom} topOrBottom={2} currPh={currBottomPhRef} />}
              <Suspense fallback={null}>
                {toggleAvatar === true && <AvatarSpawn />}
              </Suspense>

            </PresentationControls>
          </Suspense>
          <Suspense fallback={null}>
            {!props.storeConfig.isHDRLighting && <Lighting />}
          </Suspense>
          {/* <OrbitControls enablePan={true} enableDamping={true} enableRotate={true}
          minPolarAngle={Math.PI / 2} maxPolarAngle={Math.PI - Math.PI / 2} /> */}
          <OrbitControls enablePan={true} enableDamping={false} enableRotate={false} enableZoom={true} ref={orbitControlsRef} />
        </Canvas>
        {!showLoadingGif &&
          <>
            <AvatarToggle />
            <BrandTag />
            <NavButtons clickEvent={NextdressTop} topOrBottom={"Top"} type="next" />
            <NavButtons clickEvent={PreviousdressTop} topOrBottom={"Top"} type="prev" />
            {listBottomPHs.current.length > 0 && <NavButtons clickEvent={NextdressBottom} topOrBottom={"Bottom"} type="next" />}
            {listBottomPHs.current.length > 0 && <NavButtons clickEvent={PreviousdressBottom} topOrBottom={"Bottom"} type="prev" />}
          </>
        }
      </div>
    </>
  );
});

export default MainDressViewer7;