export const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL
// export const BASE_URL = "http://64.227.152.133:8080"; // USED for Local Machine

export const IS_LOCAL = false;

export function getCurrDateTime() {
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, "0");
    // const month = currentDate.toLocaleString("default", { month: "short" });
    const month = currentDate.toLocaleString("en-US", { month: "short" });
    const year = currentDate.getFullYear();
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");
    const milliseconds = String(currentDate.getMilliseconds()).padStart(3, "0");
    const dateTime = `${day}-${month}-${year}-${hours}-${minutes}-${seconds}-${milliseconds}`;
    return dateTime;
}