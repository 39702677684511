//Function to send application logs to server and also log them in the console

import axios from "axios";
import { BASE_URL, getCurrDateTime } from "./common";
import { requestHandlerFunction } from "./RequestHandler";
import DressGlbSingleton from "./DressGlbSingleton";

const dm = DressGlbSingleton.getInstance().getDebugMode()

const logger = (log, filename = "") => {
  let log_line = "";
  if (typeof log === "object") {
    log_line = JSON.stringify(log);
  } else {
    log_line = log;
  }

  let ts = getCurrDateTime();
  dm && console.log(filename + " : " + log_line + " - " + ts);
  // log_line = ts + "::" + filename + " : " + log_line;
  // const url = BASE_URL + "client-log?log_line=" + log_line + "&devname=mk2";
  // axios.get(url).catch(function (error) {
  //   dm && console.log("Error: Cannot send client log to server");
  //   dm && console.log(error);
  // });
};

function generateRandomNumber(max) {
  let randomNumber = Math.floor(Math.random() * max) + 1;
  return randomNumber.toString();
}

const getSessionId = (store_name) => {
  let sessionId = null;
  if (sessionStorage.getItem(`SBV_SessionID_${store_name}`) === null) {
    let dateTime = getCurrDateTime();
    let randomNumber = generateRandomNumber(1000);

    sessionId = dateTime + "-" + randomNumber;
    sessionStorage.setItem(`SBV_SessionID_${store_name}`, sessionId);
    dm && console.log("Setting SessionId:", sessionId);
  } else {
    let retrievedSessionId = sessionStorage.getItem(`SBV_SessionID_${store_name}`);
    sessionId = retrievedSessionId;
    dm && console.log("RetrievedSessionId:", retrievedSessionId);
  }
  return sessionId;
};

const getUserId = async (store_name) => {
  let userId = localStorage.getItem(`SBV_UserID_${store_name}`);
  if (userId === null || userId === "ux" || userId === "uy" || userId === "uz") {
    try {
      const url = BASE_URL + "/getNextUserId";
      const myData = { store_name: store_name };
      const resp = await requestHandlerFunction(url, "get_next_userId", "get", "json", myData);
      userId = resp[0].data.data;
      dm && console.log("TEST:", userId);
      localStorage.setItem(`SBV_UserID_${store_name}`, userId);
      dm && console.log("Setting userId:", userId);
    } catch (error) {
      console.error(error);
    }
  } else {
    dm && console.log("RetrievedUserId:", userId);
  }
  return userId;
};

export const UXLog = async (store_name, uxItem, artifactName = "NA", ux_value = "NA",
  miscInfo = "NA") => {
  var dateTime = getCurrDateTime();
  let sessionId = getSessionId(store_name);
  let userId = await getUserId(store_name);

  let myMem = "";
  let [jsHeapSizeLimit, totalJSHeapSize, usedJSHeapSize, jsHeapSizeSampled] = Array.from({ length: 4 }, () => 0);

  if (window.performance && window.performance.memory) {
    jsHeapSizeLimit = (window.performance.memory.jsHeapSizeLimit / 1024 / 1024).toFixed(2);
    totalJSHeapSize = (window.performance.memory.totalJSHeapSize / 1024 / 1024).toFixed(2);
    usedJSHeapSize = (window.performance.memory.usedJSHeapSize / 1024 / 1024).toFixed(2);
    jsHeapSizeSampled = (window.performance.memory.jsHeapSizeSampled / 1024 / 1024).toFixed(2);
  }
  if (performance.memory) {
    jsHeapSizeLimit = (performance.memory.jsHeapSizeLimit / 1024 / 1024).toFixed(2);
    totalJSHeapSize = (performance.memory.totalJSHeapSize / 1024 / 1024).toFixed(2);
    usedJSHeapSize = (performance.memory.usedJSHeapSize / 1024 / 1024).toFixed(2);
    jsHeapSizeSampled = (performance.memory.jsHeapSizeSampled / 1024 / 1024).toFixed(2);
  }

  if (window.webkitTemporaryStorage) {
    jsHeapSizeLimit = (window.webkitTemporaryStorage.jsHeapSizeLimit / 1024 / 1024).toFixed(2);
    totalJSHeapSize = (window.webkitTemporaryStorage.totalJSHeapSize / 1024 / 1024).toFixed(2);
    usedJSHeapSize = (window.webkitTemporaryStorage.usedJSHeapSize / 1024 / 1024).toFixed(2);
    jsHeapSizeSampled = (window.webkitTemporaryStorage.jsHeapSizeSampled / 1024 / 1024).toFixed(2);
  }

  // let availableHeapSize = ((jsHeapSizeLimit - totalJSHeapSize) / 1024 / 1024).toFixed(2);
  const unusedHeapSize = (totalJSHeapSize - usedJSHeapSize).toFixed(2);
  myMem = "Lim=" + jsHeapSizeLimit + ", Total=" + totalJSHeapSize + "(" + usedJSHeapSize + "," + unusedHeapSize + "), Gar=" + jsHeapSizeSampled;

  dm && console.log(
    "UXLog:: Store_name: " + store_name + "\n" +
    "User_id: " + userId + "\n" +
    "Session_id: " + sessionId + "\n" +
    "UX-Item: " + uxItem + "\n" +
    "TimeStamp: " + dateTime + "\n" +
    "ArtifactName: " + encodeURIComponent(artifactName) + "\n" +
    "UX-Value: " + ux_value + "\n" +
    "MiscInfo: " + miscInfo + "\n" +
    "heapSize: " + myMem
  );
  const myData = {
    store_name: encodeURIComponent(store_name),
    user_id: encodeURIComponent(userId),
    session_id: encodeURIComponent(sessionId),
    uxTimeStamp: encodeURIComponent(dateTime),
    uxItem: encodeURIComponent(uxItem),
    artifactName: encodeURIComponent(artifactName),
    uxValue: encodeURIComponent(ux_value),
    miscInfo: encodeURIComponent(miscInfo),
    heapSize: encodeURIComponent(myMem),
    devname: "shopify-react-plugin",
  };
  dm && console.log("UXLog: ", myData);
  const url = BASE_URL + "/ux-log";
  try {
    await requestHandlerFunction(url, "ux-log", "get", "arraybuffer", myData);
  } catch (error) {
    console.error(error);
  }
};

function getBrowserName() {
  const userAgent = navigator.userAgent;
  console.log("userAgent=" + userAgent);

  // Check for Chrome
  if (userAgent.indexOf("Chrome") !== -1) {
    return "Chrome";
  }

  // Check for Safari
  if (userAgent.indexOf("Safari") !== -1) {
    return "Safari";
  }

  // If neither Chrome nor Safari
  return "Neither";
};

export default logger;
