import React, { Suspense, useEffect, useMemo, useRef, useState } from "react";
import { useGLTF } from "@react-three/drei";
import { BASE_URL } from "../../lib/common";
import { dispose } from "@react-three/fiber";
import { disposeCurrentItem } from "../../lib/disposeGltf";

const Model = ({ src }) => {
 
  const gltf = useGLTF(src);
  useEffect(() => {
    return () => {
        disposeCurrentItem(gltf)
        dispose(gltf)
        useGLTF.clear(src)
    }
  }, [src])
  return <primitive object={gltf.scene} />;
};

const MemoizedModel = React.memo(
  Model,
  ({ src: prevSrc }, { src: nextSrc }) => {
    return prevSrc === nextSrc;
  }
);

const Avatar = ({ storeName }) => {
  const url = useMemo(() => BASE_URL + `/getAvatar?store_name=${storeName}`, [storeName]);
  return (
    <Suspense fallback={() => null}>
      <MemoizedModel src={url} />
    </Suspense>
  );
};

export default Avatar;
