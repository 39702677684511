import React, { useState } from 'react';
import { BASE_URL } from "../lib/common";
import cross_svg from '../assets/icons/Cross.svg';
import dark_star_svg from '../assets/icons/DarkStar.svg';
import light_star_svg from '../assets/icons/LightStar.svg';
import './css/Feedback.css';
import {UXLog} from '../lib/logger'

const Feedback = (props) => {
    const storeName = props.storeName;

    const cross_btn = BASE_URL + "/static/media/Cross.svg";
    const dark_star = BASE_URL + "/static/media/DarkStar.svg";
    const light_star = BASE_URL + "/static/media/LightStar.svg";

    const [isHidden, setIsHidden] = useState(false);
    const [selectedSmiles, setSelectedSmiles] = useState(0);
    const [hoveredSmiles, setHoveredSmiles] = useState(0);

    const handleSmileClick = (index) => {
        setSelectedSmiles(index + 1);
    };

    const handleSmileHover = (index) => {
        setHoveredSmiles(index + 1);
    };

    const handleSmileLeave = () => {
        setHoveredSmiles(0);
    };

    const closeForm = () =>{
        setIsHidden(true);
        props.setShowFeedback(false);
    }

    const submitRating = () => {
        // console.log(selectedSmiles.toString());
        UXLog(storeName, 'Feedback', 'Smile', selectedSmiles.toString());
        localStorage.setItem(`SBV_RATED${storeName}`,true);
        closeForm();
    }
    

    return (
        <div className='feedbackScreen'  style={ (isHidden) ? {display:"none"} : {}}>
            <img src={cross_btn} alt="cross_svg" className='crossSvg' onClick={closeForm}/>
            <div>
                <div className='title'>Rate your overall 3D experience</div>
                <div className='smileCont'>
                    {Array.from({ length: 5 }, (_, index) => (
                        <img
                            key={index}
                            className="smileSvg"
                            src={index < selectedSmiles || index < hoveredSmiles ? dark_star : light_star}
                            alt="smile"
                            onClick={() => handleSmileClick(index)}
                            onMouseEnter={() => handleSmileHover(index)}
                            onMouseLeave={handleSmileLeave}
                            style={{ cursor: 'pointer' }}
                        />
                    ))}
                </div>
                <div className='feedbackTxt'>
                    <h5>Meh</h5>
                    <h5>Awesome</h5>
                </div>
                <div><button className='submitBtn' onClick={submitRating}>Submit</button></div>
            </div>
        </div>
    )
}

export default Feedback;