import { BASE_URL } from "./common";
export async function showHelpMessage(my_document, storeName) {
    console.log("showHelpMessage(): This message should not come in Prod Environment.");
    let myQuery = "script[src^='" + BASE_URL + "/sbv-mix-and-match-3d.js']";
    let is_old_script_inserted = false, is_new_script_inserted = false, is_app_embed_enabled = false;
    let scriptElement = document.querySelector(myQuery);
    if (scriptElement) {
        console.log("Old Script has been inserted...");
        is_old_script_inserted = true;
    }

    myQuery = "script[src^='" + BASE_URL + "/sbv-mix-and-match-3d-tag.js']";
    scriptElement = document.querySelector(myQuery);
    if (scriptElement) {
        console.log("New Script Tag Has been inserted...");
        // TODO: Insert Help for Vintage
        is_new_script_inserted = true;
    }

    myQuery = "script[src^='" + BASE_URL + "/sbv-mix-and-match-3d-embed.js']";
    scriptElement = document.querySelector(myQuery);
    if (scriptElement) {
        console.log("Plug-in Inserted via App Embed ...");
        // TODO: Insert Help for Theme 2.0
        is_app_embed_enabled = true;
    }
    let script_insertion_status = "none";
    if (is_old_script_inserted) script_insertion_status = "old";
    if (!is_old_script_inserted && is_new_script_inserted && is_app_embed_enabled) script_insertion_status = "both";
    if (!is_old_script_inserted && is_new_script_inserted && !is_app_embed_enabled) script_insertion_status = "script-tag";
    if (!is_old_script_inserted && !is_new_script_inserted && is_app_embed_enabled) script_insertion_status = "app-embed";
    if (!is_old_script_inserted && !is_new_script_inserted && !is_app_embed_enabled) script_insertion_status = "neither";

    if (script_insertion_status !== "none" || script_insertion_status !== "neither") {
        var currentURL = window.location.pathname;
        var parts = currentURL.split('/');
        var currPageHandle = parts[parts.length - 1];

        let pageHandle = await getPageHandle(storeName);
        console.log(currPageHandle, pageHandle);

        if (currPageHandle == pageHandle) {
            // const mnm_div = my_document.getElementById("sbv-mnm-div");
            // if(!mnm_div)
            // {
            const main_Div = my_document.getElementById("MainContent");
            console.log("maincontentdiv", main_Div)
            if (main_Div) {
                main_Div.innerHTML = "<div style='display: flex;width: 80%;margin: auto;height: 36vh;justify-content: center;align-items: center;'><h1 style='font-size: 3rem;text-align: center;'>Script Successfully Added GO back to app and proceed with the next step.</h1></div>";
            }
            updateThemeStatus(storeName, script_insertion_status);
            // }
        }
    }
    // send status to backend, store in config.json
}

const updateThemeStatus = async (storeName, themeStatus) => {
    try {
        const response = await fetch(`${BASE_URL}/setThemeStatus`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ storeName, themeStatus }),
        });
        if (response.ok) {
            console.log("succesfully set themeStatus to ", themeStatus);
        } else {
            console.log("Error while updating theme status:", response.status);
        }
    } catch (error) {
        console.log("Error while updating theme status:", error);
    }
};

const getPageHandle = async (storeName) => {
    try {
        let page_handle = sessionStorage.getItem('sbv_mnm_page_handle');

        if (!page_handle) {
            const response = await fetch(`${BASE_URL}/shopify/getPageHandle?store_name=${storeName}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                }
            });
            if (response.ok) {
                const data = await response.json();
                if (data.data && data.data.page_handle) {
                    console.log("succesfully get pageHandle ", data.data.page_handle);
                    sessionStorage.setItem('sbv_mnm_page_handle', data.data.page_handle);
                    return data.data.page_handle;
                }
            } else {
                console.log("Error while getting pageHandle", response.status);
            }
        } else {
            console.log("succesfully get pageHandle ", page_handle);
            return page_handle;
        }
        
    } catch (error) {
        console.log("Error while getting pageHadle", error);
    }
    return 'none';
};
