//Singleton class to store Dress GLB files

import logger from "./logger";

let instance = null;

class DressGlbSingleton {
  static topFiles = null;
  static bottomFiles = null;
  static toCancelSeqNoTop = -1;
  static toCancelSeqNoBottom = -1;
  static debugMode = false;

  static instance;

  constructor() {
    if (!instance) {
      instance = this;
      this.topFiles = {}
      this.bottomFiles = {}
    }
    return instance;
  }


  static getInstance() {
    if (!DressGlbSingleton.instance) {
      DressGlbSingleton.instance = new DressGlbSingleton();
      return instance;
    } else {
      return DressGlbSingleton.instance;
    }
  }

  setFile(artifactID, value, topOrBottom) {
    // console.log("DressGlbSingleton(): setFile() - artifactID=" + artifactID + ", topOrBottom=" + topOrBottom)
    if (topOrBottom === 1) {
      this.topFiles[artifactID] = value;
      // logger(this.topFiles, "DressGlbSingleton - setFile");
    }
    else {
      this.bottomFiles[artifactID] = value;
      // logger(this.bottomFiles, "DressGlbSingleton - setFile");
    }
  }
  getFile(artifactID, topOrBottom) {
    // console.log("getFile(): artifactID=" + artifactID + ", topOrBottom=" + topOrBottom);
    if (topOrBottom === 1) {
      return this.topFiles[artifactID];
    } else {
      return this.bottomFiles[artifactID];
    }
  }
  deleteFile(artifactID, topOrBottom) {
    if (topOrBottom === 1 && this.topFiles[artifactID] !== "Downloading") {
      delete this.topFiles[artifactID];
    } else if (topOrBottom === 2 && this.bottomFiles[artifactID] !== "Downloading") {
      delete this.bottomFiles[artifactID];
    }
  }
  getWholeFile() {
    // logger(this.topFiles, "DressGlbFile - getWholeFile - topFiles");
    // logger(this.bottomFiles, "DressGlbFile - getWholeFile - bottomFiles");
  }
  downloadFailed(artifactID, topOrBottom) {
    if (topOrBottom === 1) { delete this.topFiles[artifactID]; } else { delete this.bottomFiles[artifactID]; }
  }
  isDownloadInProgress(topOrBottom) {
    let downloadInProgess = false;
    let myObj = null;
    if (topOrBottom === 1) {
      myObj = this.topFiles;
    }
    else {
      myObj = this.bottomFiles;
    }
    Object.keys(myObj).map((artifactID) => {
      if (myObj[artifactID] === "Downloading") {
        downloadInProgess = true;
      }
    });
    // console.log("isDownloadInProgress: topOrBottom=" + topOrBottom + ", downloadInProgess=" + downloadInProgess);
    return downloadInProgess;
  }

  checkFileNameExists(dressID) {
    if (dressID in this.topFiles || dressID in this.bottomFiles) {
      return true;
    } else {
      return false;
    }
  }
  clearFile() {
    this.topFiles = {};
    this.bottomFiles = {};
  }

  getCancelSeqNo(topOrBottom) {
    let seqno = 0;
    topOrBottom === 1 ? seqno = this.toCancelSeqNoTop : seqno = this.toCancelSeqNoBottom;
    return seqno;
  }
  setCancelSeqNo(newVal, topOrBottom) {
    this.debugMode && console.log("DressGlbSingleton: setCancelSeqNo - toCancelSeqNo=" + newVal + ", topOrBottom=" + topOrBottom);
    topOrBottom === 1 ? this.toCancelSeqNoTop = newVal : this.toCancelSeqNoBottom = newVal;
  }
  getDebugMode() {
    return this.debugMode;
  }
  setDebugMode(newDebugMode) {
    this.debugMode = newDebugMode;
  }
}

export default DressGlbSingleton;
