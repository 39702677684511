import React from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import { BASE_URL } from '../../lib/common';

const modelUrl = BASE_URL + "/static/media/LoadingBOTTOM.glb";

export const LoaderBottom = (props) => {
  const group = React.useRef()
  const { nodes, materials } = useGLTF(modelUrl)
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Scene">
        <group name="Empty" position={[-0.001, 1.075, 0.191]} rotation={[0, 0, -Math.PI / 2]} scale={0.046}>
          <group name="Circle004" position={[-0.212, -1.847, 1.114]} rotation={[1.571, 1.522, -3.142]} scale={[0.477, 4.412, 0.238]}>
            <mesh name="Circle001" geometry={nodes.Circle001.geometry} material={materials['Material.001']} />
            <mesh name="Circle001_1" geometry={nodes.Circle001_1.geometry} material={materials['Material.017']} />
            <mesh name="Circle001_2" geometry={nodes.Circle001_2.geometry} material={materials['Material.018']} />
            <mesh name="Circle001_3" geometry={nodes.Circle001_3.geometry} material={materials['Material.019']} />
            <mesh name="Circle001_4" geometry={nodes.Circle001_4.geometry} material={materials['Material.020']} />
            <mesh name="Circle001_5" geometry={nodes.Circle001_5.geometry} material={materials['Material.021']} />
            <mesh name="Circle001_6" geometry={nodes.Circle001_6.geometry} material={materials['Material.022']} />
            <mesh name="Circle001_7" geometry={nodes.Circle001_7.geometry} material={materials['Material.023']} />
            <mesh name="Circle001_8" geometry={nodes.Circle001_8.geometry} material={materials['Material.024']} />
            <mesh name="Circle001_9" geometry={nodes.Circle001_9.geometry} material={materials['Material.025']} />
            <mesh name="Circle001_10" geometry={nodes.Circle001_10.geometry} material={materials['Material.026']} />
            <mesh name="Circle001_11" geometry={nodes.Circle001_11.geometry} material={materials['Material.027']} />
            <mesh name="Circle001_12" geometry={nodes.Circle001_12.geometry} material={materials['Material.028']} />
            <mesh name="Circle001_13" geometry={nodes.Circle001_13.geometry} material={materials['Material.029']} />
            <mesh name="Circle001_14" geometry={nodes.Circle001_14.geometry} material={materials['Material.030']} />
            <mesh name="Circle001_15" geometry={nodes.Circle001_15.geometry} material={materials['Material.031']} />
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload(modelUrl)
