import React, { Profiler, useEffect, useState } from 'react';
import "./App.css";
import Checkout from "./components/Checkout";
import { BASE_URL, IS_LOCAL } from "./lib/common";
import DressGlbSingleton from './lib/DressGlbSingleton';
import MainDressViewer4 from './components/MainDressViewer4';
import MainDressViewer5 from './components/MainDressViewer5';
import Shadows from './components/Shadows'
import MainDressViewer7 from './components/MainDressViewer7';
import MainDressViewer5Debug1 from './components/MainDressViewer5Debug1';

function App(props) {
  const [dm, setDm] = useState(null);
  const [storeConfig, setStoreConfig] = useState(null);
  const [planExpired, setPlanExpired] = useState(false);
  const getStoreConfig = async () => {
    try {
      const response = await fetch(`${BASE_URL}/getStoreConfig?store_name=${props.shopValue}`);
      const data = await response.json();
      if (data && data.data) {
        setDm(data.data.debugMode);
        setStoreConfig(data.data);
        DressGlbSingleton.setDebugMode(data.data.debugMode);
      }
    } catch (error) {
      setDm(false);
      console.log("Error While Getting debugMode from store config :", error);
    }
  };
  const getPlanDetails = async () => {
    try {
      const response = await fetch(`${BASE_URL}/sbv/admin/getPlanDetails?store_name=${props.shopValue}`);
      if (response.ok) {
        const data = await response.json();
        const planDetails = data.planDetails;

        if (planDetails && planDetails.plan && planDetails.plan === "SMART TRIAL") {
          const startDate = new Date(planDetails.startDate);
          let currentDate = new Date();
          var daysDifference = Math.floor((currentDate - startDate) / (1000 * 60 * 60 * 24));
          if (planDetails.hasFExpired || daysDifference >= 84) {
            setPlanExpired(true);
          }
        }
      }
    } catch (error) {
      setDm(false);
      console.log("Error While Getting planDetails:", error);
    }
  };
  useEffect(() => {
    getStoreConfig();
    getPlanDetails();
  }, []);

  
  return (
    <div className="App">
      {!IS_LOCAL && storeConfig && < Checkout shopValue={props.shopValue} storeConfig={storeConfig} planExpired={planExpired} />}
      {/* {storeConfig && < MainDressViewer4 shopValue={props.shopValue} storeConfig={storeConfig} dm={dm} />} */}
      {/* {IS_LOCAL && storeConfig && < MainDressViewer4 shopValue={props.shopValue} storeConfig={storeConfig} dm={dm} />} */}
      {/* {(props.shopValue != 'sbv-show.myshopify.com' && props.shopValue != 'style-by-vida-dev.myshopify.com') && IS_LOCAL && storeConfig && < MainDressViewer5 shopValue={props.shopValue} storeConfig={storeConfig} dm={dm} />} */}
      {/* {(props.shopValue == 'sbv-show.myshopify.com' || props.shopValue == 'style-by-vida-dev.myshopify.com') && IS_LOCAL && storeConfig && < Shadows shopValue={props.shopValue} storeConfig={storeConfig} dm={dm} />} */}
      {/* <Profiler id="App" onRender={onRender}> */}
      {IS_LOCAL && storeConfig && < MainDressViewer5Debug1 shopValue={props.shopValue} storeConfig={storeConfig} dm={dm} planExpired={planExpired} />}
      {/* </Profiler> */}
    </div>
  );
}

export default App;
